<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h2 class="mt-2">プライバシーポリシー</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="py-2">
                <v-list-item-content
                  >当ウェブサイトは、株式会社ランダムファクトリー（以下「当社」）が運営する企業サイト、オンラインショッピングサイト、情報サイト、その他の当社が運営するサイトです。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">個人情報保護方針</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">個人情報の管理</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、
                  セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >個人情報の利用目的</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >本ウェブサイトでは、お名前、e-mailアドレス、電話番号、住所等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。
                  お客さまからお預かりした個人情報は、当社からの商品の発送やご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >個人情報の第三者への開示・提供の禁止</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
                  <li>
                    当社の国内外の親会社、または関連及び関係会社同士の間で、利用目的を遂行するために必要な場合
                  </li>
                  <li>お客さまの同意がある場合</li>
                  <li>
                    お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
                  </li>
                  <li>法令に基づき開示することが必要である場合</li>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >個人情報の安全対策
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">ご本人の照会 </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >法令、規範の遵守と見直し
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">お問い合わせ </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <P>当社の個人情報の取扱に関するお問い合わせは下記までご連絡ください。</P>
                  <p>株式会社ランダムファクトリー</p>
                  <p>住所：東京都渋谷区道玄坂1丁目10番8号 渋谷道玄坂東急ビル2F−C</p>

                  <p>電話：03-6774-7991</p>
                  <p>メール：support@randomfactory.co.jp</p>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.custom-content {
  line-height: 1.4; /* これにより行の高さが増加します */
}
</style>
