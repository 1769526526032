<script setup>
import { ref, computed } from "vue";
import db from "../main.js";
import { collection, getDocs } from "firebase/firestore";

const userPurchases = ref([]);
const ssrProducts = ref([]);
const rProducts = ref([]); // 'r' ランクの製品を格納するためのリアクティブ変数
const props = defineProps({
  user: String,
});

// 特定のユーザーのUIDを設定

// 'Goods_products' コレクションから 'ssr' と 'r' ランクの製品を取得
const campaignsRef = collection(db, "Goods_products");

getDocs(campaignsRef).then((campaignSnapshot) => {
  campaignSnapshot.forEach((campaignDoc) => {
    const campaignId = campaignDoc.id;
    const productsRef = collection(db, "Goods_products", campaignId, "products");
    getDocs(productsRef).then((productsSnapshot) => {
      productsSnapshot.forEach((productDoc) => {
        const productData = productDoc.data();
        if (productData.product_rank === "ssr") {
          ssrProducts.value.push({ ...productData, productid: productDoc.id });
        } else if (productData.product_rank === "r") {
          // 'r' ランクの製品も取得
          rProducts.value.push({ ...productData, productid: productDoc.id });
        }
      });
    });
  });
});

// 'users/{uid}/Goods_productorders' からユーザーの購入履歴を取得
const ordersRef = collection(db, `users/${props.user.uid}/Goods_productorders`);

getDocs(ordersRef).then((ordersSnapshot) => {
  ordersSnapshot.forEach((orderDoc) => {
    const orderProductsRef = collection(db, "Goods_productorders", orderDoc.id, "orderproducts");
    getDocs(orderProductsRef).then((orderProductsSnapshot) => {
      orderProductsSnapshot.forEach((orderProductDoc) => {
        const orderData = orderProductDoc.data();
        orderData.products.forEach((product) => {
          userPurchases.value.push({
            ...product,
            vendorname: orderData.vendorname, // vendorname をここで追加
          });
        });
      });
    });
  });
});

// SSRとR製品と購入履歴を照合するリアクティブなプロパティ
const combinedProductsWithPurchaseHistory = computed(() => {
  // SSR製品と購入履歴を照合
  const ssrWithHistory = ssrProducts.value.map((product) => ({
    ...product,
    hasPurchase: userPurchases.value.some(
      (purchase) =>
        purchase.vendorname === product.vendorname && purchase.productid === product.productid
    ),
    rank: "ssr",
  }));

  // R製品と購入履歴を照合
  const rWithHistory = rProducts.value.map((product) => ({
    ...product,
    hasPurchase: userPurchases.value.some(
      (purchase) =>
        purchase.vendorname === product.vendorname && purchase.productid === product.productid
    ),
    rank: "r",
  }));

  // SSR製品を先に、その後にR製品を配置
  return [...ssrWithHistory, ...rWithHistory];
});
</script>
<template>
  <h2 class="title">マイコレクション</h2>
  <div class="products-grid">
    <div
      v-for="product in combinedProductsWithPurchaseHistory"
      :key="product.productid"
      class="product-container"
      :class="{
        'has-purchase': product.hasPurchase,
        'no-purchase': !product.hasPurchase,
        'ssr-product': product.rank === 'ssr',
        'r-product': product.rank === 'r',
      }"
    >
      <img :src="product.product_image" :alt="product.product_name" class="product-image" />
      <div class="product-name">{{ product.product_name }} ({{ product.rank.toUpperCase() }})</div>
    </div>
  </div>
</template>

<style scoped>
.products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.product-container {
  flex-basis: calc(33.333% - 0px);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.product-image {
  /* width: 100%; */
  border: 3px solid transparent; /* デフォルトの枠線 */
  width: 100px;
  height: auto;
}

.ssr-product .product-image {
  border-color: gold; /* 'ssr' ランクの場合の金色の枠 */
}

.r-product .product-image {
  border-color: silver; /* 'r' ランクの場合の銀色の枠 */
}

.no-purchase .product-image {
  opacity: 0.3; /* 照合NOの場合の透明度70% */
}

.product-name {
  text-align: center;

  margin-left: 10px;
  margin-right: 10px;
}
.title {
  text-align: center;
  margin-top: 20px;
}
</style>
