<script setup>
import { toRefs } from "vue";

const props = defineProps({
  eventdatestart: String,
  eventdateend: String,
  price: String,
  shippingdate: String,
  shop_name: String,
  googlemapURL: String,
});

// const { eventdatestart } = toRefs(props);
const { price } = toRefs(props);
const { shop_name } = toRefs(props);
const { googlemapURL } = toRefs(props);
// const { shippingdate } = toRefs(props);
</script>

<template>
  <div class="box">
    <!-- <p class="text">
      <img class="icon" src="img/time.png" />イベント期間： {{ eventdatestart }}から
    </p>
    <p class="textend">{{ eventdateend }}まで</p> -->

    <p class="text"><img class="icon" src="img/price.png" />販売価格：１回{{ price }}円（税込）</p>
    <p class="text">
      <img class="icon" src="img/shipping.png" />ご提供方法：<a :href="googlemapURL">{{
        shop_name
      }}</a
      >までお越しの上、店舗内にてワンショットお飲みいただけます
    </p>
    <p class="text">
      <img class="icon" src="img/shipping.png" />引き換え期限：くじ購入日より90日間となります
    </p>
    <!-- <p class="text"><img class="icon" src="img/otodoke.png" />お届け目安：{{ shippingdate }}</p> -->
    <p class="text_kome">※在庫が無くなり次第、販売終了します</p>
  </div>
</template>

<style scoped>
.text {
  font-size: 12px;
  text-align: left;
  margin: 10px 0 0 15px;
}
.textend {
  font-size: 12px;
  text-align: left;
  margin: 0px 0 0 121px;
}
.box {
  /* border-radius: 15px; */
  border: 1px solid #000;
  background-color: white;
  width: 83%;
  height: 130px;
  padding: 1px 1px 0 0;
  margin: 20px 0 0 0;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  /* box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px; */
}
.icon {
  width: 20px;
}
.text_kome {
  font-size: 10px;
  text-align: left;
  margin: 5px 0 0 15px;
}
</style>
