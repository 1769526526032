<script setup>
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
const signout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.log("サインアウト");
      // Sign-out successful.
      router.push("/login");
    })
    .catch((error) => {
      console.log(error);
      // An error happened.
    });
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <v-row>
        <v-col cols="12" class="text-center mb-5">
          <h2 class="mt-12 title">マイアカウント</h2>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center">
          <div class="box" @click="toLink('shipping')">
            <img src="img/myaccount_change.png" alt="edit" class="edit" />
            <div class="hikikae">▶︎ワンショットくじ引き換え</div>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center">
          <div class="box" @click="toLink('editprofile')">
            <img src=img/myaccount_profile.png alt="edit" class="edit" />
            <div class="info">▶︎プロフィール情報の編集</div>
          </div>
        </v-col>

        <!-- <v-col cols="12" md="4" class="d-flex justify-center">
          <div class="box" @click="toLink('editpaymentinfo')">
            <img src=img/myaccount_payment.png alt="edit" class="edit" />
            <div class="payment">▶︎お支払い方法の変更</div>
          </div>
        </v-col> -->

        <v-col cols="12" md="3" class="d-flex justify-center">
          <div class="box" @click="toLink('orderhistory')">
            <img src="img/myaccount_order.png" alt="edit" class="edit" />
            <div class="order">▶︎注文履歴</div>
          </div>
        </v-col>

        <v-col cols="12" md="3" class="d-flex justify-center">
          <div class="box" @click="toLink('mycollections')">
            <img src="img/myaccount_collection.png" alt="edit" class="edit" />
            <div class="collections">▶︎マイコレクション</div>
          </div>
        </v-col>

        <!-- <v-col cols="12" md="4" class="d-flex justify-center">
          <div class="box" @click="toLink('shippinghistory')">
            <img src="img/myaccount_order.png" alt="edit" class="edit" />
            <div class="orderhistory">▶︎発送依頼履歴</div>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <div class="logoutcontainer">
            <a class="logout" @click="signout()">▶︎ログアウト</a>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 17px;
}
.box {
  border-radius: 20px;
  border: 1px solid #000;
  background-color: white;
  width: 300px;
  height: 75px;
  padding: 0.5em 1em;
  /* margin: 2em 0; */
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(233, 233, 233, 0.9) 5px 5px 0px 0px;
}

.edit {
  height: 45px;
  margin: 10px 220px 0px 0;
  /* position: absolute;
  top: 0;
  left: 0.001; */
}
.info {
  font-size: 16px;
  margin: -33px -47px 0px 0;
}
.payment {
  font-size: 16px;
  margin: -33px -30px 0px 0;
}
.address {
  font-size: 16px;
  margin: -33px -30px 0px 0;
}
.order {
  font-size: 16px;
  margin: -33px 64px 0 0;
}
.hikikae {
  font-size: 16px;
  margin: -33px -57px 0 0;
}

.collections {
  font-size: 16px;
  margin: -33px 0px 0 0;
}
.orderhistory {
  font-size: 16px;
  margin: -33px 0px 0 0;
}
.logout {
  font-size: 16px;
  text-decoration: underline;
  color: rgb(165 71 71);
}
.logoutcontainer {
  text-align: right;
  padding-right: 20px;
  margin-top: 8px;
  margin-right: 5px;
}
</style>
