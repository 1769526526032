<script setup>
// eslintでEpsilonTokenがグローバル変数であることを知らせる
/* global EpsilonToken */
import { ref, watch, watchEffect, computed } from "vue";
import { doc, getDoc, setDoc, onSnapshot, collection } from "firebase/firestore";
import MainAddCardDialog from "/src/components/molecules/MainAddCardDialog.vue";
// import BannerPicture from "/src/components/atoms/BannerPicture.vue";
// import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
// import SellInfo from "/src/components/atoms/SellInfo.vue";
import db from "../main.js"; //追加
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const router = useRouter();
// import axios from "axios";
// const jsonpAdapter = require("axios-jsonp");
// import { useRouter } from "vue-router";
const props = defineProps({
  user: String,
});
const route = useRoute();
const h2flag = ref(true);
const isLoadingforAddCard = ref(false);
const isLoadingforPayment = ref(true);
//環境変数用
const gachaVideoUrlA = ref(process.env.VUE_APP_GCS_URL + "/video/video_A.mp4");
const gachaVideoUrlB = ref(process.env.VUE_APP_GCS_URL + "/video/video_B.mp4");
const gachaVideoUrlC = ref(process.env.VUE_APP_GCS_URL + "/video/video_C.mp4");

// const isMuted = ref(true);
const firststep = ref(true);
// const firststepnotwo = ref(false);
// const secondstep = ref(false);
const thirdstep = ref(false);

//default_paymentmethodの取得（取得できた場合カード決済したことがあると判定）
const default_paymentmethodAlready = ref(null);
// then()を使用する場合
const docpayment = doc(db, "users", props.user.uid, "sub_accounts", "sub_accounts");
getDoc(docpayment)
  .then((docSnappayment) => {
    if (docSnappayment.exists()) {
      default_paymentmethodAlready.value = docSnappayment.data().default_paymentmethod;
      console.log(default_paymentmethodAlready.value);
      if (default_paymentmethodAlready.value == "creditcard") {
        firststep.value = false;
        thirdstep.value = true;
        console.log("カード決済したことがある");
        const functions = getFunctions();
        functions.region = "asia-northeast1";
        const myCallableFunction = httpsCallable(functions, "Goods_GetCredit");
        myCallableFunction({})
          .then((result) => {
            console.log("Full result:", result);
            const cardBrand = result.data.cardBrand.$
              ? result.data.cardBrand.$.card_brand
              : undefined;
            const cardNumberLast = result.data.cardNumberLast;
            const cardExpireFrom = result.data.cardexpire.$
              ? result.data.cardexpire.$.card_expire
              : undefined;

            cardlast4.value = cardNumberLast;
            cardexpire.value = cardExpireFrom;
            cardbrand.value = cardBrand;
            console.log("Card Brand:", cardBrand);
            console.log("Card Number Last:", cardNumberLast);
            isLoadingforPayment.value = false;
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      // ドキュメントが存在しない場合の処理
      console.log("No such document!");
    }
  })
  .catch((error) => {
    // エラー処理
    console.error(error);
  });

// console.log(default_paymentmethodAlready.value);
// if (default_paymentmethodAlready.value != null) {
//   firststep.value = true;
//   // thirdstep.value = true;
//   console.log("カード決済したことがある");
// }

const loadUserData = async () => {
  const userRef = doc(db, "users", props.user.uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const userData = userSnap.data();
    if (userData.birthdate) {
      selectedYear.value = userData.birthdate.year;
      selectedMonth.value = userData.birthdate.month;
      selectedDay.value = userData.birthdate.day;
    }
  } else {
    console.log(`No data available for user with UID: ${props.user.uid}`);
  }
};
loadUserData();
// const addresses = ref([]);
// const getAddressData = async () => {
//   console.log("zzzz");
//   addresses.value = [];
//   const querySnapshot = await getDocs(collection(db, "users", props.user.uid, "addresses"));
//   querySnapshot.forEach((doc) => {
//     console.log(doc.id, " => ", doc.data());
//     const data = doc.data();
//     console.log(data);
//     addresses.value.push(data);
//     console.log("zzzzpp");
//     console.log(addresses.value);
//   });
// };
// getAddressData();

//登録済みクレジットカード呼び出し
// const cardsInfo = ref(null);
// const defaultCard = ref(null);
// async function getCreditCard() {
//   // isLoadingforcredit.value = true;
//   console.log("function呼び出しカード");
//   const functions = getFunctions();
//   functions.region = "asia-northeast1";

//   const myCallableFunction = httpsCallable(functions, "Common_cardslist");
//   myCallableFunction({})
//     .then((result) => {
//       console.log(result.data.cards.data);
//       cardsInfo.value = result.data.cards.data;
//       // console.log(result.data.defaultCard.id);
//       defaultCard.value = result.data.defaultCardId;
//       console.log("デフォルトカードアイディー" + defaultCard.value);

//       // isLoadingforcredit.value = false;
//     })
//     .catch((error) => {
//       console.log(error.message);
//     });
// }
// getCreditCard();

// const chooseaddress = ref([]);
// const toSelectPayment = (index) => {
//   firststep.value = false;

//   secondstep.value = true;
//   console.log(index);
//   chooseaddress.value = addresses.value[index];
//   console.log(chooseaddress);
// };

// const card = ref();
const cardId = ref();

// const unitPrice = ref();
// const toComfirmOrderUsingCard = (index) => {
//   card.value = cardsInfo.value[index];
//   console.log(card);
//   console.log(card.value.id);
//   cardId.value = card.value.id;
//   choosecard.value = cardsInfo.value[index];
//   console.log(choosecard.value);
//   firststep.value = false;
//   thirdstep.value = true;
//   // fifthstep.value = true;
//   // shippingfeeandorderprice();
//   // this.$router.push('/buy/comfirmOrder', () => {});
// };

//表示用の金額と送料
// const shippingFee = ref();
// const totallPrice = ref();
const unitPrice = ref();
const quantity = ref();
const sumPrice = ref();
const live_status = ref("live");
const stockCheck = ref(true);
const culTotalPrice = async () => {
  //ここでlive_statusとstockを取得
  onSnapshot(doc(db, "Goods_products", route.query.vendorname), (doc) => {
    console.log("Current data: ", doc.data());
    live_status.value = doc.data().live_status;
    // if (live_status.value !== "live") {
    //   router.push(`itemlist?vendorname=${route.query.vendorname}`); // リダイレクト
    // }
  });

  const query = collection(db, "Goods_products", route.query.vendorname, "products");

  // リアルタイムでの更新リスナーを設定
  onSnapshot(query, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(data);
      console.log(data.stock_quantity);

      // 在庫が0の場合、強制リダイレクト設定
      if (data.stock_quantity <= 0) {
        // router.push(`itemlist?vendorname=${route.query.vendorname}`); // リダイレクト
        stockCheck.value = false;
        console.log("在庫切れ");
      }
    });
  });

  const docRef = doc(db, "Goods_products", route.query.vendorname);
  const docSnap = await getDoc(docRef);

  if (live_status.value !== "live") {
    router.push(`itemlist?vendorname=${route.query.vendorname}`); // リダイレクト
  }

  const docRefcart = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
  const docSnapcart = await getDoc(docRefcart);
  quantity.value = docSnapcart.data().quantity;
  unitPrice.value = docSnap.data().price;
  console.log(unitPrice.value);
  sumPrice.value = quantity.value * unitPrice.value;
  console.log(sumPrice.value);
  // totallPrice.value = quantity.value * 550 + shippingFee.value;
};
culTotalPrice();
// const shippingfeeandorderprice = async () => {
//   const docRef = doc(db, "Goods_products", route.query.vendorname);
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data());
//     unitPrice.value = docSnap.data().price;
//   } else {
//     // docSnap.data() will be undefined in this case
//     console.log("No such document!");
//   }
//   console.log("送料の計算ーーー");
//   const functions = getFunctions();
//   functions.region = "asia-northeast1";
//   const func = httpsCallable(functions, "Goods_calcOrderPrice_and_shippingFee");

//   try {
//     const res = await func({ name: "hoge" });
//     console.log(res.data.price_fee.totalPrice);
//     console.log(res.data.price_fee.shippingFee);
//     shippingFee.value = res.data.price_fee.shippingFee;
//     totallPrice.value = res.data.price_fee.totalPrice;

//     const docRef = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       console.log("Document data:", docSnap.data());
//       const data = docSnap.data();
//       console.log(data);
//       // vendorname.value = data.vendorname;
//       // vendorname_ja = data.vendorname_ja;
//       quantity.value = data.quantity;
//       sumPrice.value = data.quantity * unitPrice.value;
//       // this.totallPrice = data.quantity * 550 + 550;
//       // console.log(quantity);
//     } else {
//       // doc.data() will be undefined in this case
//       console.log("No such document!");
//     }
//   } catch (error) {
//     // eslint-disable-next-line no-console
//     // console.log(error.message);
//   }
// };
const selectnumber = ref(1);
//購入数を取得
const getCart = async () => {
  const docRef = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    selectnumber.value = docSnap.data().quantity;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
  // const querySnapshot = await getDocs(collection(db, "ArCard_cards"));
  // querySnapshot.forEach((doc) => {
  //   console.log(`${doc.id} => ${doc.data()}`);
  // });
};

getCart();

const choosebuycard = ref(null);
const usecarrierpaymentflag = ref(false);
const comfirmBuy = async () => {
  isMutedforvideo.value = true;
  if (usecarrierpaymentflag.value) {
    console.log("キャリア決済");
    // emit("comfirmGoodsforcarrierpayment", selectnumber, choosebuycard, chooseaddress, cardId);
  } else {
    if (default_paymentmethodAlready.value == "creditcard") {
      console.log(choosebuycard);
      // console.log(chooseaddress);
      console.log(cardId);
      // comfirmGoods(selectnumber, choosebuycard, chooseaddress, cardId);
      comfirmGoodsSecond(selectnumber, choosebuycard, cardId);
      if (ageSave.value === true) {
        console.log("年齢保存");
        const userRef = doc(db, "users", props.user.uid);

        try {
          await setDoc(
            userRef,
            {
              birthdate: {
                year: selectedYear.value,
                month: selectedMonth.value,
                day: selectedDay.value,
              },
            },
            { merge: true }
          );

          console.log("Document successfully written!");
        } catch (error) {
          console.error("Error writing to Firestore: ", error);
        }
      }
    } else {
      console.log(choosebuycard);
      // console.log(chooseaddress);
      console.log(cardId);
      // comfirmGoods(selectnumber, choosebuycard, chooseaddress, cardId);
      comfirmGoods(selectnumber, choosebuycard, cardId);
      if (ageSave.value === true) {
        console.log("年齢保存");
        const userRef = doc(db, "users", props.user.uid);

        try {
          await setDoc(
            userRef,
            {
              birthdate: {
                year: selectedYear.value,
                month: selectedMonth.value,
                day: selectedDay.value,
              },
            },
            { merge: true }
          );

          console.log("Document successfully written!");
        } catch (error) {
          console.error("Error writing to Firestore: ", error);
        }
      }
    }
  }
};

const creditcarderror = ref(false);
const orderId = ref();
//商品のグッズガチャ購入(クレジットカード)-1回目
const comfirmGoods = (selectnumber, choosebuycard, cardId) => {
  h2flag.value = false;
  thirdstep.value = false;
  sixstep.value = true;
  console.log("cfmgoods2");
  console.log(choosebuycard);
  // console.log(chooseaddress);
  console.log(cardId);
  console.log("function呼び出し");
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const selectnumberNo = selectnumber.value;
  // const choosebuycardNo = choosebuycard.value;
  // const chooseaddressNo = chooseaddress.value;
  const vendornameNo = route.query.vendorname;
  // const cardIdNo = cardId.value;
  // const shippingFeeNumberNo = shippingFee.value;
  console.log(selectnumberNo);
  // console.log(choosebuycardNo);
  console.log(vendornameNo);
  // console.log(cardIdNo);
  console.log(tokenValue.value);

  const myCallableFunction = httpsCallable(functions, "Goods_GoodsCharge");
  myCallableFunction({
    selectnumber: selectnumberNo,
    // choosebuycard: choosebuycardNo,
    // chooseaddress: chooseaddressNo,
    vendorname: vendornameNo,
    // card_id: cardIdNo,
    gmoToken: tokenValue.value,
    // shippingFeeNumber: shippingFeeNumberNo,
  })
    .then((result) => {
      console.log("Full result:", result);
      console.log(result);
      console.log(result.data.stripeOrderId);
      orderId.value = result.data.stripeOrderId;
      console.log(orderId.value);
    })
    .catch((error) => {
      // エラーメッセージからエラーコードを抽出
      const errorCodeMatch = error.message.match(/code: (\w+)/);
      const errorCode = errorCodeMatch ? errorCodeMatch[1] : null;

      if (errorCode) {
        // エラーコードが存在する場合、それをコンソールに表示
        console.error(`Error occurred with code: ${errorCode}`);
        thirdstep.value = true;
        sixstep.value = false;
        creditcarderror.value = true;
      } else {
        // エラーコードがない場合、通常のエラーメッセージを表示
        console.error(error.message);
        thirdstep.value = true;
        sixstep.value = false;
        creditcarderror.value = true;
      }
    });
};

//商品のグッズガチャ購入(クレジットカード)-2回目(登録済みカードを使用)
const comfirmGoodsSecond = (selectnumber, choosebuycard, cardId) => {
  h2flag.value = false;
  thirdstep.value = false;
  sixstep.value = true;
  console.log("cfmgoods2");
  console.log(choosebuycard);
  // console.log(chooseaddress);
  console.log(cardId);
  console.log("function呼び出し");
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const selectnumberNo = selectnumber.value;
  // const choosebuycardNo = choosebuycard.value;
  // const chooseaddressNo = chooseaddress.value;
  const vendornameNo = route.query.vendorname;
  // const cardIdNo = cardId.value;
  // const shippingFeeNumberNo = shippingFee.value;
  console.log(selectnumberNo);
  // console.log(choosebuycardNo);
  console.log(vendornameNo);
  // console.log(cardIdNo);
  console.log(tokenValue.value);

  const myCallableFunction = httpsCallable(functions, "Goods_GoodsChargSecond");
  myCallableFunction({
    selectnumber: selectnumberNo,
    // choosebuycard: choosebuycardNo,
    // chooseaddress: chooseaddressNo,
    vendorname: vendornameNo,
    // card_id: cardIdNo,
    // gmoToken: tokenValue.value,
    // shippingFeeNumber: shippingFeeNumberNo,
  })
    .then((result) => {
      console.log("Full result:", result);
      console.log(result);
      console.log(result.data.stripeOrderId);
      orderId.value = result.data.stripeOrderId;
      console.log(orderId.value);
    })
    .catch((error) => {
      // エラーメッセージからエラーコードを抽出
      const errorCodeMatch = error.message.match(/code: (\w+)/);
      const errorCode = errorCodeMatch ? errorCodeMatch[1] : null;

      if (errorCode) {
        // エラーコードが存在する場合、それをコンソールに表示
        console.error(`Error occurred with code: ${errorCode}`);
        thirdstep.value = true;
        sixstep.value = false;
        creditcarderror.value = true;
      } else {
        // エラーコードがない場合、通常のエラーメッセージを表示
        console.error(error.message);
        thirdstep.value = true;
        sixstep.value = false;
        creditcarderror.value = true;
      }
    });
};

const order = ref(null);
const dataLenght = ref();
const getOrderinfo = async () => {
  console.log(orderId.value);
  if (orderId.value != "undefined") {
    const functions = getFunctions();
    functions.region = "asia-northeast1"; // これを追加
    const addMessage = httpsCallable(functions, "Goods_getOrderProducts");
    addMessage({ text: orderId.value }).then((result) => {
      order.value = result.data;
      console.log(order.value);
      // オーダー情報の配列の長さを取得する
      dataLenght.value = result.data.length - 1;
      console.log(dataLenght);
    });
  } else {
    // isDeclinePayment.value = true;
    console.log("決済失敗");
  }
};

watch(orderId.value, () => {
  console.logO("watchiiiiiii");
  getOrderinfo();
  console.log(orderId.value); // ここで任意のメソッドを呼び出す
});
watchEffect(() => {
  console.log("watchiiiiiii");
  getOrderinfo();
  console.log(orderId);
});

const sixstep = ref(false);

const videoplayflag = ref(true);
const productindex = ref(0);
const showimageflag = ref(false);
const allproductsflag = ref(false);

const isMutedforvideo = ref(true);

const videoend = () => {
  isMutedforvideo.value = true;
  videoplayflag.value = false;
  showimageflag.value = true;
};

const nextproduct = () => {
  isMutedforvideo.value = true;
  productindex.value += 1;
  showimageflag.value = false;
  videoplayflag.value = true;
  console.log(productindex.value);
};

const toAllproducts = () => {
  showimageflag.value = false;
  videoplayflag.value = false;
  allproductsflag.value = true;
};
const isLoadingforvideo = ref(true);
const handleLoadedData = () => {
  console.log("動画の読み込みが完了しました");
  isLoadingforvideo.value = false;
};
// const toAddAddress = () => {
//   firststep.value = false;
//   firststepnotwo.value = true;
// };
// const returnsteps = () => {
//   firststep.value = true;
//   firststepnotwo.value = false;
// };

//アドレス追加
// const postcode = ref(null);
// const todohuken = ref(null);
// const moreaddress = ref(null);
// const address2 = ref(null);
// const name = ref(null);
// const phoneno = ref(null);
// const sendaddress = async () => {
//   console.log("住所を追加");
//   console.log(postcode.value);
//   //refを消す
//   const postcodeno = postcode.value;
//   const address1 = todohuken.value + moreaddress.value;
//   const address1no = address1;
//   const address2no = address2.value;
//   const nameno = name.value;
//   const phonenono = phoneno.value;
//   console.log(nameno);
//   const functions = getFunctions();
//   functions.region = "asia-northeast1"; // これを追加
//   const addMessage = httpsCallable(functions, "Goods_Addaddress");
//   console.log(address1no);
//   await addMessage({
//     postcode: postcodeno,
//     address1: address1no,
//     address2: address2no,
//     name: nameno,
//     phoneno: phonenono,
//   }).then(async (res) => {
//     console.log(res);
//     await getAddressData();
//     firststep.value = true;
//     firststepnotwo.value = false;
//     // dialog.value = false;
//   });
// };
// const getaddressfrompostcode = () => {
//   const apiURL = "https://api.zipaddress.net/?zipcode=" + postcode.value;
//   axios.get(apiURL, { adapter: jsonpAdapter }).then(async (res) => {
//     const response = res.data;
//     todohuken.value = response.pref;
//     if (response.city) {
//       todohuken.value = response.pref;
//       moreaddress.value = response.city + response.town;
//     } else {
//       console.log("郵便番号間違い");
//       // postcodeError.value = "郵便番号が間違っています。";
//     }
//     console.log(res);
//   });
// };

// const toAddCard = () => {
//   firststep.value = false;
//   firststepnotwo.value = true;
// };

const selectedYear = ref(new Date().getFullYear());
const selectedMonth = ref(1);
const selectedDay = ref(1);
const saveBirthdate = ref(false);

const yearOptions = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
const dayOptions = computed(() => {
  const daysInMonth = new Date(selectedYear.value, selectedMonth.value, 0).getDate();
  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
});

watch([selectedYear, selectedMonth], () => {
  const maxDay = new Date(selectedYear.value, selectedMonth.value, 0).getDate();
  if (selectedDay.value > maxDay) {
    selectedDay.value = maxDay;
  }
});

const handleYearMonthChange = () => {
  const maxDay = new Date(selectedYear.value, selectedMonth.value, 0).getDate();
  if (selectedDay.value > maxDay) {
    selectedDay.value = maxDay;
  }
};

const age = computed(() => {
  const today = new Date();
  const birthDate = new Date(selectedYear.value, selectedMonth.value - 1, selectedDay.value);
  let calculatedAge = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    calculatedAge--;
  }
  return calculatedAge;
});

const ageSave = ref(false);
const handleCheckboxChange = async () => {
  if (saveBirthdate.value && age.value >= 20) {
    console.log("年齢保存");
    ageSave.value = true;
  }
};

// 子コンポーネントからのイベントをハンドリングする関数
const handleCardAdded = (cardData) => {
  isLoadingforAddCard.value = true;
  console.log("Card data from child:", cardData);
  console.log(cardData.number);
  cardlast4.value = cardData.number.slice(-4);
  cardexpire.value = cardData.expiry;
  const cardObj = {
    cardno: cardData.number,
    expire: cardData.expiry,
    securitycode: cardData.cvc,
    holdername: cardData.name,
  };
  console.log(cardObj);
  console.log(cardObj.expire);
  EpsilonToken.init("74256870");
  EpsilonToken.getToken(cardObj, "execTrade");
};

const tokenValue = ref("");

const execTrade = async (response) => {
  console.log(response);
  if (response.resultCode !== "000") {
    isLoadingforAddCard.value = false;
    alert("カード登録中にエラーが発生しました");
  } else {
    isLoadingforAddCard.value = false;
    firststep.value = false;
    thirdstep.value = true;
    // cardObj.value.cardno = "";
    // cardObj.value.expire_year = "";
    // cardObj.value.expire_month = "";
    // cardObj.value.securitycode = "";
    tokenValue.value = response.tokenObject.token;
    console.log(tokenValue.value);
    isLoadingforPayment.value = false;
    // const functions = getFunctions();

    // try {
    //   const response = await httpsCallable(functions, "callBProjectFunction")({});
    //   console.log(response);
    //   console.log(response.data);
    //   result.value = response.data;
    // } catch (err) {
    //   error.value = err.message;
    // }
  }
};

// const result = ref("");
// const error = ref("");
window.execTrade = execTrade;
const cardlast4 = ref("");
const cardexpire = ref("");
const cardbrand = ref("");

const changeCredit = () => {
  isLoadingforPayment.value = true;
  default_paymentmethodAlready.value = null;
  firststep.value = true;
  thirdstep.value = false;
};

const dialogclose = () => {
  isMutedforvideo.value = true;
  router.push("/", () => {});
};

const toggleMute = () => {
  isMutedforvideo.value = !isMutedforvideo.value; // 現在の値を反転させる
};
</script>

<template>
  <v-app>
    <h2 class="title" v-if="h2flag == true">購入手続き</h2>

    <div class="body" v-show="firststep">
      <div v-if="isLoadingforAddCard" class="loading-overlay">
        <div class="loading-message">ローディング中<span class="dots">...</span></div>
      </div>
      <h3 class="titlesub">クレジットカードを追加</h3>
      <div class="spacer"></div>

      <MainAddCardDialog @card-added="handleCardAdded" />
    </div>
    <div v-show="thirdstep">
      <div v-if="isLoadingforPayment" class="loading-overlay">
        <div class="loading-message">ローディング中<span class="dots">...</span></div>
      </div>
      <h3 class="titlesub">購入確認</h3>
      <div v-show="creditcarderror">
        <p class="text_age">
          クレジットカードの決済に失敗しました。別のクレジットカードをご使用ください。
        </p>
      </div>
      <div class="agetitle">
        <p>年齢確認にご協力ください</p>
        <p class="text_age">
          ※２０歳未満の飲酒は法律で禁止されています。<br />※２０歳未満に対してのお酒の販売は致しません。
        </p>
      </div>

      <v-app>
        <div class="agecontainer pa-4">
          <p>生年月日</p>
          <v-row class="mt-1">
            <v-col cols="4">
              <v-select
                :items="yearOptions"
                v-model="selectedYear"
                label="年"
                outlined
                @change="handleYearMonthChange"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="monthOptions"
                v-model="selectedMonth"
                label="月"
                outlined
                @change="handleYearMonthChange"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="dayOptions" v-model="selectedDay" label="日" outlined></v-select>
            </v-col>
          </v-row>
          <div>年齢: {{ age }}歳</div>
          <div class="mt-3">
            <v-checkbox
              v-model="saveBirthdate"
              label="生年月日を保存する"
              @change="handleCheckboxChange"
            ></v-checkbox>
          </div>
        </div>
      </v-app>

      <div class="btn_conteiner unit orderbutton">
        <button
          v-if="live_status === 'live' && stockCheck === true"
          class="normal boxtitle"
          @click="comfirmBuy()"
          :disabled="!(age >= 20)"
        >
          注文を確定する
        </button>
        <button v-else class="normal boxtitleend">このくじは終了しました</button>
      </div>
      <div class="unit">
        <dl class="price">
          <dt>購入数</dt>
          <dd>{{ selectnumber }}回</dd>
        </dl>
      </div>
      <div class="unit">
        <dl class="price">
          <dt>合計</dt>
          <dd>￥ {{ sumPrice }}円（税込）</dd>
        </dl>
      </div>

      <p>お支払い方法</p>
      <div
        v-if="usecarrierpaymentflag"
        class="unit_outline position_relative"
        style="padding-bottom: 20px"
      >
        <p>スマートフォンキャリア決済</p>
        <button class="change-button" @click="changePayment">▷お支払い方法を変更</button>
      </div>
      <div v-else class="unit_outline-card position_relative">
        <p class="card-content">
          クレジットカード
          <!-- {{ cardbrand }}<br /> -->
          末尾 {{ cardlast4 }}<br />
          有効期限 {{ cardexpire }}<br />
        </p>
        <p>
          <button class="change-button-card" @click="changeCredit">▷お支払い方法を変更</button>
        </p>
      </div>

      <div class="btn_conteiner unit">
        <button
          v-if="live_status === 'live' && stockCheck === true"
          class="normal boxtitle"
          @click="comfirmBuy()"
          :disabled="!(age >= 20)"
        >
          注文を確定する
        </button>
        <button v-else class="normal boxtitleend">このくじは終了しました</button>
      </div>
    </div>
    <div v-show="sixstep">
      <div class="body">
        <!-- ローディング表示 -->
        <div v-if="isLoadingforvideo" class="loading-overlay">
          <svg class="loading-circle" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
          </svg>
          <div class="loading-message">ローディング中<span class="dots">...</span></div>
        </div>
        <div class="unit">
          <div
            v-if="order != null && videoplayflag == true && order[productindex].product_movie == 1"
          >
            <div class="btn_sound">
              <!-- 音ボタンの挙動-->
              <img v-if="isMutedforvideo" @click="toggleMute()" src="img/soundoff.png" />
              <img v-if="!isMutedforvideo" @click="toggleMute()" src="img/soundon.png" />
            </div>
            <video
              class="gacha_movie"
              :src="gachaVideoUrlA"
              :autoplay="true"
              :muted="isMutedforvideo"
              playsinline
              @loadeddata="handleLoadedData"
              @play="handlePlay"
              @ended="videoend"
            ></video>
          </div>
          <div
            v-if="order != null && videoplayflag == true && order[productindex].product_movie == 2"
          >
            <div class="btn_sound">
              <!-- 音ボタンの挙動-->
              <img v-if="isMutedforvideo" @click="toggleMute()" src="img/soundoff.png" />
              <img v-if="!isMutedforvideo" @click="toggleMute()" src="img/soundon.png" />
            </div>
            <video
              class="gacha_movie"
              :src="gachaVideoUrlB"
              :autoplay="true"
              :muted="isMutedforvideo"
              playsinline
              @loadeddata="handleLoadedData"
              @play="handlePlay"
              @ended="videoend"
            ></video>
          </div>
          <div
            v-if="order != null && videoplayflag == true && order[productindex].product_movie == 3"
          >
            <div class="btn_sound">
              <!-- 音ボタンの挙動-->
              <img v-if="isMutedforvideo" @click="toggleMute()" src="img/soundoff.png" />
              <img v-if="!isMutedforvideo" @click="toggleMute()" src="img/soundon.png" />
            </div>
            <video
              class="gacha_movie"
              :src="gachaVideoUrlC"
              :autoplay="true"
              :muted="isMutedforvideo"
              playsinline
              @loadeddata="handleLoadedData"
              @play="handlePlay"
              @ended="videoend"
            ></video>
          </div>

          <div
            class="gacha_result"
            v-if="order != null && showimageflag == true && dataLenght != productindex"
          >
            <p class="image"><img :src="order[productindex].product_image" /></p>
            <p class="name">{{ order[productindex].product_name }}</p>
            <p class="rank">{{ order[productindex].product_rank_ja }}</p>
            <div class="unit textaline_center">
              <button class="normal smallbox smallboxordered" @click="nextproduct">
                次の商品へ
              </button>
              <button class="normal smallbox smallboxordered" @click="toAllproducts">
                全てをスキップして結果一覧へ
              </button>
            </div>
          </div>

          <div v-if="order != null && showimageflag == true && dataLenght == productindex">
            <img class="endpicsize" :src="order[productindex].product_image" />
            <p>{{ order[productindex].product_name }}</p>
            <p>{{ order[productindex].product_rank_ja }}</p>
            <button class="normal smallbox smallboxordered" @click="toAllproducts">
              結果へ進む
            </button>
          </div>

          <div class="productcontainer" v-if="allproductsflag">
            <!-- 全ての商品を表示 -->
            <div class="subproductcontaine" v-for="(val, key) in order" :key="key">
              <img class="productpic" :src="val['product_image']" />
              <p class="productrank">{{ val["product_rank_ja"] }}</p>
              <p class="productname">{{ val["product_name"] }}</p>
            </div>
            <div class="btn_conteiner" style="width: 100%; display: flex; justify-content: center">
              <button class="normal smallbox smallboxordered" @click="dialogclose">閉じる</button>
            </div>
          </div>

          <div v-show="isDeclinePayment">
            <h2>
              決済に失敗しました。支払い方法を変更するか、別のクレジットカードをご使用ください。
            </h2>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.title {
  margin: 20px 0 0 0;
}
.titlesub {
  margin: 40px 0 0 0;
}
/* 追加 */
.spacer {
  margin-bottom: 30px;
}

.title {
  margin: 20px 0 0 0;
}

.textaline_center {
  text-align: center;
}

.margn_bottom_reset {
  margin-bottom: 0 !important;
}

.unit_outline {
  border: 1px solid black;
  padding: 20px;
  margin: 20px 20px 20px 20px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  /* border-radius: 20px 20px 20px 20px; */
  display: flex; /* フレックスボックスを使う */
  flex-direction: column; /* フレックスボックスを縦方向にする */
  align-items: center; /* 中央に揃える */
  justify-content: flex-start; /* 上側に揃える */
}
.normal {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px 0 0 0;
  padding: 10px;
}
.price {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 3px 0; /* reduce margin here */
}

.price dt {
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px; /* add padding-bottom here */
  margin: 5px 0 0 0;
}

.price dd {
  border-bottom: 1px solid #aaa;
  margin: 5px 0; /* reduce margin here */
}

.price dd:last-child {
  border-bottom: none;
}
.boxtitle {
  border: solid 1px rgb(202, 68, 70);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(202, 68, 70);
  border-radius: 20px 20px 20px 20px;
  color: white;
}
.boxtitleend {
  border: solid 1px rgb(242, 242, 242);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 20px 20px;
  color: balck;
}

.smallbox {
  /* border: solid 1px rgb(46, 68, 99); */
  display: inline-block;
  padding: 15px 15px 15px 15px;
  /* background-color: rgb(46, 68, 99); */
  border-radius: 20px 20px 20px 20px;
  color: white;
}
.smallboxAddress {
  border: solid 1px rgb(155, 155, 155);
  background-color: rgb(155, 155, 155);
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
}
.smallboxAddressChange {
  border: solid 1px rgb(155, 155, 155);
  background-color: rgb(155, 155, 155);
}
.smallboxAddAddress {
  color: black;
  border: solid 1px rgb(217, 217, 217);
  background-color: rgb(217, 217, 217);
}
.smallboxordered {
  border: solid 1px rgb(155, 155, 155);
  background-color: rgb(155, 155, 155);
}
.position_relative {
  position: relative;
}

.change-button {
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: -20px;
}
.change-button-margin {
  margin-bottom: 20px;
}
.gacha_movie {
  width: 100%;
}
.gacha_result .image {
  text-align: center;
}
.gacha_result .image img {
  width: 50%;
}
.gacha_result .name {
  text-align: center;
}
.gacha_result .rank {
  text-align: center;
}

.body {
  margin: 10px 0 0 0;
}

.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
}
.productpic {
  width: 100%;
  margin-bottom: 0.7em;
}
.productname {
  font-size: 0.8rem;
}
.productrank {
  font-size: 0.8rem;
}
.address-content {
  text-align: left; /* 左揃え */
  margin-left: 0px; /* 左側にマージンを追加 */
}
.card-content {
  text-align: left; /* 左揃え */
  margin-left: -50px; /* 左側にマージンを追加 */
}

.unit_outline-card {
  border: 1px solid black;
  padding: 30px;
  margin: 20px 20px 20px 20px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  /* border-radius: 20px 20px 20px 20px; */
  display: flex; /* フレックスボックスを使う */
  flex-direction: column; /* フレックスボックスを縦方向にする */
  align-items: center; /* 中央に揃える */
  justify-content: flex-start; /* 上側に揃える */
}

.card-info {
  text-align: left;
  margin-left: -50px;
}
.orderbutton {
  margin: 20px 0 20px 0;
}
.endpicsize {
  width: 50%;
}
.bodyaddress {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.backgroundaddress {
  width: 100%;
  padding: 30px;
}

.fieldaddress {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.titleaddress {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.inputpostcode,
.inputtodohuken,
.inputaddress,
.inputname,
.inputnameaddress {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.erroraddress {
  color: red;
  font-size: 12px;
}

.unitaddress {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return {
  background: #ddd;
  color: #333;
}

.normaladdress {
  background: #4caf50;
  color: white;
}

button a {
  color: inherit;
  text-decoration: none;
}

.change-button-card {
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: 0px;
  margin-right: 5px;
}

.agetitle {
  margin-top: 25px;
}
.text_age {
  color: red;
  margin: 10px 0 0 15px;
}
.normal.boxtitle:disabled {
  background-color: #cccccc; /* グレー背景 */
  border: none; /* 枠線を無しにする */
}
@media (min-width: 960px) {
  .unit_outline {
    border: 1px solid black;
    padding: 20px;
    margin: 20px 100px 20px 100px;
    box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
    text-align: left;
    /* border-radius: 20px 20px 20px 20px; */
    display: flex; /* フレックスボックスを使う */
    flex-direction: column; /* フレックスボックスを縦方向にする */
    align-items: center; /* 中央に揃える */
    justify-content: flex-start; /* 上側に揃える */
  }
  .price {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 3px 0; /* reduce margin here */
  }

  .price dt {
    font-weight: bold;
    border-bottom: 1px solid #aaa;
    padding-bottom: 5px; /* add padding-bottom here */
    margin: 5px 100px 0 100px;
  }

  .price dd {
    border-bottom: 1px solid #aaa;
    margin: 5px 100px 0px 100px; /* reduce margin here */
  }

  .price dd:last-child {
    border-bottom: none;
  }
  .unit_outline-card {
    border: 1px solid black;
    padding: 30px;
    margin: 20px 100px 20px 100px;
    box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
    text-align: left;
    /* border-radius: 20px 20px 20px 20px; */
    display: flex; /* フレックスボックスを使う */
    flex-direction: column; /* フレックスボックスを縦方向にする */
    align-items: center; /* 中央に揃える */
    justify-content: flex-start; /* 上側に揃える */
  }
  .endpicsize {
    width: 30%;
  }
  .gacha_result .image img {
    width: 30%;
  }
  .gacha_movie {
    width: 25%;
  }
  .gacha_result {
    margin-top: 10px;
  }
  .agecontainer {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* 全体にオーバーレイするために fixed を使用 */
  top: 0;
  left: 0;
  width: 100%; /* 画面全体の幅 */
  height: 100%; /* 画面全体の高さ */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明のグレー */
  z-index: 1000; /* 他の要素の上に表示 */
}
.loading-message {
  color: white;
  font-size: 20px;
}
.loading-circle {
  width: 50px;
  height: 50px;
}

.path {
  stroke: #3498db;
  stroke-linecap: round;
  animation: dash 17s ease-in-out forwards;
}

.btn_sound {
  position: absolute;
  top: 50px;
  right: 5%;
  width: 30px;
  z-index: 1000;
  cursor: pointer;
}
.btn_sound img {
  width: 100%;
}

@keyframes dash {
  0% {
    stroke-dasharray: 0, 125.6; /* 125.6は円周の長さ (2πr) */
  }
  90% {
    stroke-dasharray: 113.04, 125.6; /* 90%の長さ */
  }
  100% {
    stroke-dasharray: 125.6, 125.6; /* 完全な円 */
  }
}
.dots {
  display: inline-block;
  animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
