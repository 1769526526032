<script setup>
import { ref } from "vue";
import db from "../main.js"; //追加
import { collection, getDocs, query, where } from "firebase/firestore";

import EventSchedule from "/src/components/molecules/EventSchedule.vue";
import TheCarousel from "/src/components/molecules/TheCarousel.vue";

//イベントデータ
const eventData = ref([]);
const ievent = ref(0);
const getLiveData = async () => {
  const q = query(collection(db, "Goods_products"), where("eventcategory", "==", "oneshot"));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    console.log(data);

    eventData.value.push(data);

    console.log(eventData.value);
    ievent.value += 1;
    console.log(ievent.value);
  });
};
getLiveData();
</script>

<template>
  <TheCarousel :productsData="eventData" />
  <h2 class="title">ーワンショットくじラインナップー</h2>
  <EventSchedule :eventData="eventData" />
</template>

<style scoped>
.title {
  font-size: 22px;
  margin: 40px 0 40px 0px;
}
</style>
