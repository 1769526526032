<script setup>
import { ref } from "vue";
import BannerPicture from "/src/components/atoms/BannerPicture.vue";
import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
import ShopBar from "/src/components/atoms/ShopBar.vue";
import SellInfo from "/src/components/atoms/SellInfo.vue";
import GoodsList from "/src/components/molecules/GoodsList.vue";
import TokushohoInfo from "/src/components/molecules/TokushohoInfo.vue";
// import ItemRanking from "/src/components/molecules/ItemRanking.vue";
import db from "../main.js"; //追加
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const vendorname = route.query.vendorname;
console.log(vendorname);
const vtuber_image = ref(null);
const event_title = ref(null);
const shop_name = ref(null);
// const product_image = ref(null);
// const cardsData = ref([]);
const eventdatestart = ref(null);
const eventdateend = ref(null);
const price = ref(null);
const shippingdate = ref(null);
//イベント情報呼び出し
// const eventData = ref([]);
const live_status = ref("");
const googlemapURL = ref(null);
//特商法関連
const storeemail = ref(null);
const storephone = ref(null);
const storeaddress = ref(null);
const storeperson = ref(null);
const getEventData = async () => {
  // const docRef = doc(db, "Goods_products", vendorname);
  onSnapshot(doc(db, "Goods_products", vendorname), (doc) => {
    console.log("Current data: ", doc.data());
    // console.log("Document data:", docSnap.data());
    const data = doc.data();
    console.log(data);

    vtuber_image.value = data.image;
    console.log(vtuber_image.value);
    event_title.value = data.eventtitle;
    shop_name.value = data.storename;
    price.value = data.price;
    shippingdate.value = data.shipping_date;
    live_status.value = data.live_status;
    googlemapURL.value = data.googlemapURL;
    storeemail.value = data.storeemail;
    storephone.value = data.storephone;
    storeaddress.value = data.storeaddress;
    storeperson.value = data.storeperson;
  });
};

getEventData();

//当該ベンダーのサブコレクション取得
const goodsData = ref([]);
//ストックが切れていないかをチェック
const stockCheck = ref(false);
const getProductsData = async () => {
  const query = collection(db, "Goods_products", vendorname, "products");

  // リアルタイムでの更新リスナーを設定
  onSnapshot(query, (querySnapshot) => {
    goodsData.value = []; // 既存のデータをクリア
    let isStockAvailable = true; // 在庫の有無をチェックする変数

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(data);
      goodsData.value.push(data); // 新しいデータを追加
      console.log(data.stock_quantity);
      // 在庫が0より小さい場合、isStockAvailable を false に設定
      if (data.stock_quantity <= 0) {
        isStockAvailable = false;
      }
    });

    // 全ての商品の在庫がある場合のみ stockCheck を true に設定
    stockCheck.value = isStockAvailable;
    console.log("在庫チェック: ", stockCheck.value);
    console.log(goodsData.value);
  });
};

getProductsData();

// //当選確率を取得

const winningRateSsr = ref("");
const winningRateR = ref("");
const winningRateA = ref("");
const winningRateB = ref("");
const winningRateC = ref("");
const getWinningRateData = async () => {
  try {
    const docRef = doc(db, "Goods_products", vendorname, "rarity", "rarity");
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      console.log(data);
      winningRateSsr.value = (data.ssr * 100).toFixed(2);
      winningRateR.value = (data.r * 100).toFixed(2);
      winningRateA.value = (data.a * 100).toFixed(2);
      winningRateB.value = (data.b * 100).toFixed(2);
      winningRateC.value = (data.c * 100).toFixed(2);
      console.log(winningRateSsr.value);
      console.log(winningRateR.value);
    } else {
      console.log("ドキュメントが見つかりません");
    }
  } catch (error) {
    console.error("データの取得中にエラーが発生しました:", error);
  }
};

getWinningRateData();

const toMain = () => {
  router.push(`ordergoods?vendorname=${vendorname}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};

const stock = ref(0);
stock.value = 1;
</script>

<template>
  <BannerPicture :vtuber_image="vtuber_image" />
  <CampaignTitleBar :event_title="event_title" />
  <ShopBar :shop_name="shop_name" />

  <SellInfo
    :eventdatestart="eventdatestart"
    :eventdateend="eventdateend"
    :price="price"
    :shippingdate="shippingdate"
    :shop_name="shop_name"
    :googlemapURL="googlemapURL"
  />
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          v-if="live_status === 'live' && stockCheck === true"
          class="title boxtitle"
          @click="toMain"
        >
          くじを購入する
        </v-btn>
        <v-btn v-else class="title boxtitleend"> このくじは終了しました </v-btn>
      </v-col>
    </v-row>

    <p class="text_age">
      ※２０歳未満の飲酒は法律で禁止されています。<br />※２０歳未満に対してのお酒の販売・提供は致しません。
    </p>

    <v-row class="mb-4">
      <v-col cols="12" class="text-center">
        <h3 class="title">お酒一覧</h3>
      </v-col>
      <!-- <v-col cols="12" class="text-center">
        <p clas="stock">
          SS賞残り<span class="stock_no">{{ stock }}</span
          >ショット(杯)
        </p>
      </v-col> -->
    </v-row>

    <GoodsList
      class="goodslist"
      :goodsData="goodsData"
      :winningRateSsr="winningRateSsr"
      :winningRateR="winningRateR"
      :winningRateA="winningRateA"
      :winningRateB="winningRateB"
      :winningRateC="winningRateC"
    />
    <!-- ... -->
    <TokushohoInfo
      :storeName="shop_name"
      :storeEmail="storeemail"
      :storePhone="storephone"
      :storeAddress="storeaddress"
      :storePerson="storeperson"
    />
  </v-container>
</template>

<style scoped>
.goodslist {
  margin: 20px 0 0 0;
}
.title {
  margin: 40px 0 40px 0;
}
.boxtitle {
  border: solid 1px rgb(202, 68, 70);
  display: inline-block;
  /* padding: 15px 15px 15px 15px; */
  background-color: rgb(202, 68, 70);
  border-radius: 20px 20px 20px 20px;
  color: white;
  width: 173px;
  height: 49px;
  font-size: 15px;

  /* text-align: left; */
}
.boxtitleend {
  border: solid 1px rgb(242, 242, 242);
  display: inline-block;
  /* padding: 15px 15px 15px 15px; */
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 20px 20px;
  color: black;
  width: 200px;
  height: 49px;
  font-size: 15px;

  /* text-align: left; */
}
.stock {
  font-size: 15px;
}
.stock_no {
  font-size: 30px;
}
.text_age {
  font-size: 13px;
  text-align: center;
  color: red;
  margin: 0px 0 0 15px;
}
</style>
