<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h2 class="mt-2">特定商取引法に基づく表記</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="py-2">
                <v-list-item-content>事業者名: 株式会社ランダムファクトリー</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>責任者: 伊奈守道</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>Webサイト: oneshotkuji.randombox.jp</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content
                  >住所: 東京都渋谷区道玄坂1丁目10番8号渋谷道玄坂東急ビル2F−C</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>電話番号: 03 - 6774 - 7991</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content class="custom-content">
                  メールアドレス: oneshotkuji@randombox.jp
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content
                  >販売価格: 各イベントページの記載の価格にて販売。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い方法: クレジットカードが使用できます。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い時期:
                  <div>
                    クレジットカード払い:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日にお支払いといたします。
                  </div>
                  <!-- <div>
                    スマートフォンキャリア決済:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日に携帯料金と合算でのお支払いとなります。
                  </div> -->
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  商品の引渡し:
                  各イベントページに記載の店舗にてお引き渡し、店舗内のみにて飲食が可能です。持ち帰りは不可とさせていただきます。引渡し期限は購入日より90日間といたします。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  商品の返品:商品のキャンセル、返品、交換は一切受け付けておりません。<br />ただし、商品が明らかに不良品の場合のみ交換いたします。<br />万が一、交換商品が無い場合は商品代金を返金または代品での対応とさせて頂きます。
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.custom-content {
  line-height: 1.4; /* これにより行の高さが増加します */
}
</style>
