<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">メールアドレス確認完了</h2>
      </div>
      <div class="mt-12">
        <p>
          ご登録のメールアドレスの認証手続きが完了いたしました。<br />ただいまより、ワンショットくじをご購入いただけます。
        </p>
      </div>
      <div>
        <v-btn
          height="35"
          width="110"
          rounded="pill"
          @click="toTop()"
          color="rgb(202, 202, 202)"
          class="mt-10"
        >
          <a class="login">▶︎TOPページへ</a>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const toTop = () => {
  router.push("/");
};
</script>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 20px;
}
.login {
  /* color: rgb(255 255 255); */
  color: black;
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}

.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dot {
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: blink;
}

.dot1 {
  animation-delay: 0.2s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.6s;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    /* color: rgb(255 255 255); */
    font-size: 12px;
  }
}
</style>
