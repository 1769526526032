<script setup>
import { ref, defineEmits } from "vue";

const cardNumber = ref("");
const cardName = ref("");
const cardExpiry = ref("");
const cardCVC = ref("");
const cardExpirationMonth = ref("");
const cardExpirationYear = ref("");

// イベントの定義
const emits = defineEmits(["card-added"]);

// 有効期限の入力を監視して、必要に応じて "/" を追加
// watchEffect(() => {
//   if (
//     (cardExpiry.value.length === 2 && !cardExpiry.value.includes("/")) ||
//     (cardExpiry.value.length === 4 && !cardExpiry.value.includes("/"))
//   ) {
//     cardExpiry.value = cardExpiry.value.substring(0, 2) + "/" + cardExpiry.value.substring(2);
//   }
// });

const formattedExpiry = () => {
  cardExpiry.value = 20 + cardExpirationYear.value + cardExpirationMonth.value;
  console.log(cardExpiry.value);
  return cardExpiry.value;
};

const onCardNumberInput = (event) => {
  cardNumber.value = event.target.value.replace(/\D/g, ""); // 数字のみを許可
};

const onCardNameInput = (event) => {
  cardName.value = event.target.value.replace(/[^a-zA-Z\s]/g, "").toUpperCase(); // 英字のみを許可し、大文字に変換
};

// const onCardExpiryInput = (event) => {
//   cardExpiry.value = event.target.value.replace(/[^0-9/]/g, ""); // 数字と "/" のみを許可
// };

const onCardExpirationMonthInput = (event) => {
  cardExpirationMonth.value = event.target.value.replace(/\D/g, ""); // 数字のみを許可
};

const onCardExpirationYearInput = (event) => {
  cardExpirationYear.value = event.target.value.replace(/\D/g, ""); // 数字のみを許可
};

const onCardCVCInput = (event) => {
  cardCVC.value = event.target.value.replace(/\D/g, ""); // 数字のみを許可
};

const addCard = () => {
  emits("card-added", {
    number: cardNumber.value,
    name: cardName.value,
    expiry: formattedExpiry(),
    cvc: cardCVC.value,
  });
};
</script>

<template>
  <div class="body">
    <div class="fields-container">
      <div class="field">
        <p class="title">カード番号</p>
        <input
          v-model="cardNumber"
          name="cardNumber"
          placeholder="カード番号を入力"
          @input="onCardNumberInput"
          class="inputarea"
        />
      </div>

      <div class="field">
        <p class="title">カードのお名前(英字)</p>
        <input
          v-model="cardName"
          placeholder="お名前を入力"
          @input="onCardNameInput"
          class="inputarea"
        />
      </div>

      <div class="field">
        <p class="title">有効期限 (MM/YY)</p>
        <div class="expiry-field-container">
          <input
            v-model="cardExpirationMonth"
            class="expiry-inputMonth inputareaexpire"
            name="cardExpirationMonth"
            placeholder="例: 12"
            maxlength="2"
            @input="onCardExpirationMonthInput"
          />
          <span class="expiry-separator">/</span>
          <input
            v-model="cardExpirationYear"
            class="expiry-inputYear inputareaexpire"
            name="cardExpirationYear"
            placeholder="例: 24"
            maxlength="2"
            @input="onCardExpirationYearInput"
          />
        </div>
      </div>

      <div class="field">
        <p class="title">セキュリティーコード</p>
        <input
          v-model="cardCVC"
          placeholder="裏面の3桁or4桁のコード"
          @input="onCardCVCInput"
          class="inputarea"
        />
      </div>

      <div class="unit">
        <div class="credittypetitle">以下のクレジットカードをご利用いただけます。</div>
        <img src="img/visa.png" class="logopic" />
        <img src="img/mastercard.png" class="logopic" />
        <img src="img/AXP.jpg" class="logopic" />
        <img src="img/jcb.png" class="logopic" />
      </div>

      <button class="normal" @click="addCard">カードを追加</button>
    </div>
  </div>
</template>

<style scoped>
.maintitle {
  font-size: 20px; /* フォントサイズを増やす */
  font-weight: bold;
  margin: 50px 0 30px 0; /* 上下にマージンを取る */
}
.body {
  background-color: #ffffff;
  color: #333;
  display: flex; /* 追加 */
  flex-direction: column; /* 追加 */
  align-items: center; /* 追加 */
}

.field {
  display: flex; /* 追加 */
  justify-content: center; /* 追加 */
  flex-direction: column; /* 追加 */
  align-items: center; /* 追加 */
  margin-bottom: 2rem;
}

.field .title {
  font-size: 1rem;
  /* margin-bottom: 0.5rem; */
  display: block;
  color: #333;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.inputcard,
.inputexpiry,
.inputcvc {
  display: block;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.inputcard:focus,
.inputexpiry:focus,
.inputcvc:focus {
  border-color: #0084ff;
  box-shadow: 0px 0px 5px rgba(0, 132, 255, 0.5);
}

.unit {
  margin-bottom: 2rem;
  text-align: center;
}

.logopic {
  max-height: 2rem;
  margin: 0 0.5rem;
}

.errormessage {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.btn_conteiner {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

button.normal,
button.return {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button.normal:hover,
button.normal:active {
  background-color: #2980b9;
}

button.return {
  background-color: #bdc3c7;
  color: #7f8c8d;
}

button.return:hover,
button.return:active {
  background-color: #95a5a6;
}

.inputcard {
  display: block;
  padding: 1rem;
  font-size: 1rem;
  width: 60%; /* Update this value */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.inputexpiry,
.inputcvc {
  display: block;
  padding: 1rem;
  font-size: 1rem;
  width: 30%; /* Update this value */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}
.credittypetitle {
  margin-bottom: 10px;
}
button.normal {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 25px; /* 角丸にするために、境界半径を25pxに設定 */
  border: none;
  background-color: rgb(155, 155, 155); /* 背景色を#3498dbからrgb(155, 155, 155)に変更 */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button.normal:hover,
button.normal:active {
  background-color: rgb(125, 125, 125); /* HoverとActive状態の背景色を少し暗くする */
}

button.return {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 25px; /* 角丸にするために、境界半径を25pxに設定 */
  border: none;
  background-color: rgb(217, 217, 217); /* 背景色を#bdc3c7からrgb(217, 217, 217)に変更 */
  color: #7f8c8d;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button.return:hover,
button.return:active {
  background-color: rgb(187, 187, 187); /* HoverとActive状態の背景色を少し暗くする */
}

.expiry-field-container {
  display: flex;
  align-items: center;
}

.expiry-inputMonth {
  width: 45px;
  text-align: right;
  margin-right: 5px; /* あるいは必要に応じて調整 */
  margin-left: 5px;
}
.expiry-inputYear {
  width: 45px;
  margin-right: 5px; /* あるいは必要に応じて調整 */
  margin-left: 5px;
}

.expiry-separator {
  margin-right: 5px; /* あるいは必要に応じて調整 */
}
.inputarea {
  background-color: #f5f5f5;
  width: 200px;
  height: 30px;
}
.inputareaexpire {
  background-color: #f5f5f5;
}
</style>
