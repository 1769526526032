<script setup>
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import db from "../main.js";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router"; // 追加: useRouteのインポート

const orderDetails = ref({});

const route = useRoute(); // 追加: 現在のルートオブジェクトを取得

const orderNumber = ref();
orderNumber.value = route.query.orderNumber; // 追加: URLからorderNumberクエリパラメータを取得
const status = ref();
const totalPrice = ref();
const eventtitle = ref();

const getOrderdetail = async () => {
  const docRef = doc(db, "goods_shippingorders", orderNumber.value);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    orderDetails.value = docSnap.data(); // ドキュメントのデータをorderDetailsに保存
    status.value = docSnap.data().status;
    totalPrice.value = docSnap.data().total_price;
    eventtitle.value = docSnap.data().eventtitle;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};

getOrderdetail();
const orderDetailsSub = ref([]);

const getOrderdetailsub = async () => {
  const collectionRef = collection(
    db,
    "goods_shippingorders",
    orderNumber.value,
    "shippingproducts"
  );
  const querySnapshot = await getDocs(collectionRef);

  querySnapshot.forEach((doc) => {
    console.log("Document data:", doc.data());
    orderDetailsSub.value.push(doc.data()); // ドキュメントのデータをorderDetailsSubに追加
    console.log(orderDetailsSub.value);
  });
  console.log("Final product data:", orderDetailsSub.value);
};

getOrderdetailsub();

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>
<template>
  <v-app>
    <div class="maincontainer">
      <div class="title-container">
        <h2 class="title">お酒くじ発送依頼履歴</h2>
      </div>
      <div class="details-container">
        <p>イベント名：{{ eventtitle }}</p>
        <p>依頼番号：{{ orderNumber }}</p>
        <p>配達状況：{{ status }}</p>
        <p>発送料：{{ totalPrice }}</p>
      </div>
    </div>
    <div
      v-for="(order, index) in orderDetailsSub"
      :key="'order-' + index"
      class="product-container"
    >
      <div v-for="(product, pIndex) in order.products" :key="'product-' + pIndex">
        <div class="product-details">
          <div class="product-info">
            <!-- <div class="labels">
              <p><span class="underline">商品名:</span></p>
              <p><span class="underline">ランク:</span></p>
              <p><span class="underline">商品ID:</span></p>
              <p><span class="underline">配送状況:</span></p>
            </div> -->
            <div class="values">
              <p class="values"><span class="underline">商品名:</span>{{ product.product_name }}</p>
              <p class="values">
                <span class="underline">ランク:</span>{{ product.product_rank_ja }}
              </p>
              <p class="values"><span class="underline">商品ID:</span>{{ product.productid }}</p>
              <!-- <p class="values">
                <span class="underline">配送状況:</span>{{ product.shipping_status }}
              </p> -->
            </div>
          </div>
          <img :src="product.product_image" class="product-image" alt="Product Image" />
        </div>
        <hr v-if="pIndex < order.products.length - 1" class="product-divider" />
        <!-- 商品間の線 -->
      </div>
    </div>
    <div class="returncontainer" @click="toLink('shippinghistory')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title-container {
  margin: 50px 50px;
}

.details-container {
  text-align: left;
  margin-left: 40px;
}

.product-container {
  border: 1px solid #000;
  padding: 20px;
  margin: 20px; /* 上下左右のマージンを追加 */
  border-radius: 15px; /* 角を丸く */
  padding-bottom: 40px;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.product-info {
  display: flex;
}

.values {
  text-align: left; /* 左揃えに */
  margin-right: 10px;
  font-size: 14px;
}

.values p {
  margin-bottom: 15px; /* または、必要に応じて適切な値に変更 */
}

hr {
  border-top: 1px solid #000; /* 商品間の線 */
  margin-bottom: 20px; /* 商品間の線のためのスペースを確保 */
}

.underline {
  text-decoration: underline; /* ラベルに下線を追加 */
  font-size: 14px;
}

.product-image {
  width: 100px;
  height: auto;
}
.product-divider {
  border-top: 1px solid #000;
  margin: 20px 0; /* 上下にマージンを追加 */
}
.labels p {
  margin-bottom: 0px; /* または、必要に応じて適切な値に変更 */
}

.returncontainer {
  margin: 30px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
</style>
