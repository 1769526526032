<script setup>
import { toRefs } from "vue";
const props = defineProps({
  shop_name: String,
});

const { shop_name } = toRefs(props);
console.log(shop_name.value);
</script>

<template>
  <h2 class="name">店舗名：{{ shop_name }}</h2>
</template>

<style scoped>
.name {
  font-size: 16px;
  text-align: center;
  margin: 10px 0 0 15px;
}
</style>
