<script setup>
import { ref } from "vue";

const props = defineProps({
  // 商品ランクごとに分類された商品リスト {"ランク文字列": [商品オブジェクト, ...], ...}
  items: {
    type: Object,
    required: true,
  },
});

// リストの開閉制御
const open = ref([]);
</script>

<template>
  <v-list lines="one" v-model:opened="open">
    <v-list-group
      v-for="rank in Object.keys(props.items).sort()"
      :key="rank"
      :value="rank"
      :title="rank"
    >
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-gift-outline"
          :title="rank"
          variant="elevated"
        ></v-list-item>
      </template>

      <v-list-item
        v-for="item in props.items[rank]"
        :key="item.created_at_human"
        :title="item.product_name"
        :prepend-avatar="item.product_image"
        color="blue-grey-lighten-4"
        variant="elevated"
      >
        <v-list-item-subtitle>引き換え日時: {{ item.exchange_date_human }}</v-list-item-subtitle>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<style scoped></style>
