<script setup>
import { ref } from "vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter, useRoute } from "vue-router";

const email = ref(null);
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];
const password = ref(null);
const passwordRules = [(v) => !!v || "パスワードを入力してください"];
const text = ref(null);
const isLoading = ref(false);
const show = ref(false); // この行を変更

const toggleShow = () => {
  show.value = !show.value; // refを使う場合、.valueを使ってアクセス
};

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};

const route = useRoute();
const redirectURL = ref(null);
redirectURL.value = ref(route.query.redirect);
console.log(route.path);
console.log(redirectURL.value.value);
const signIn = () => {
  isLoading.value = true;
  console.log("サインイン開始");
  console.log(email.value);
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);
      console.log(redirectURL.value);
      console.log("ログイン成功"); // eslint-disable-line no-console
      if (redirectURL.value.value == null) {
        isLoading.value = false;
        toLink("/");
      } else {
        console.log(redirectURL.value.value);
        toLink(redirectURL.value.value);
        isLoading.value = false;
      }
    })
    .catch((error) => {
      isLoading.value = false;
      console.log(error); // eslint-disable-line no-console
      if (error.code === "auth/user-not-found") {
        text.value = "メールアドレスかパスワードに誤りがあります";
      } else if (error.code === "auth/invalid-email") {
        text.value = "メールアドレスの形式をご確認ください";
      } else {
        text.value = "メールアドレスかパスワードに誤りがあります";
      }
    });
};
const deletemessage = () => {
  text.value = null;
};
</script>
<template>
  <v-app>
    <div class="maincontainer">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-message">ローディング中<span class="dots">...</span></div>
      </div>
      <div>
        <h2 class="mt-12 title">アカウントにログイン</h2>
      </div>
      <div class="mt-12">
        <v-form ref="form" lazy-validation class="form">
          <div @click="deletemessage">
            <div class="mailaddress">メールアドレス</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
            />
          </div>
          <div @click="deletemessage">
            <div class="mt-5 mailaddress">パスワード</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="password"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              density="compact"
              required
              @click:append="toggleShow"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div>
            <v-btn
              class="mt-5"
              height="35"
              width="100"
              rounded="pill"
              @click="signIn"
              color="rgb(202, 68, 70)"
            >
              <a class="login">ログイン</a>
            </v-btn>
          </div>
        </v-form>
      </div>
      <div class="resetpasswordcontainer">
        <a class="resetpassword" @click="toLink('/resetpassword')">
          ▶︎パスワードをお忘れの方はこちら
        </a>
      </div>
      <div>
        <v-btn
          class="mt-16 mb-16"
          height="35"
          width="200"
          rounded="pill"
          @click="toLink('/signup')"
          color="rgba(60,39,35 41%) "
        >
          <a class="signup">初めての方の登録はこちら</a>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 20px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* 全体にオーバーレイするために fixed を使用 */
  top: 0;
  left: 0;
  width: 100%; /* 画面全体の幅 */
  height: 100%; /* 画面全体の高さ */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明のグレー */
  z-index: 1000; /* 他の要素の上に表示 */
}

.loading-message {
  color: white;
  font-size: 20px;
}
.dots {
  display: inline-block;
  animation: blink 1s steps(5, start) infinite;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
