<template>
  <v-container>
    <h2>お問い合わせ</h2>
  </v-container>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="name"
        :rules="[(v) => !!v || '名前は必須です']"
        label="お名前"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="メールアドレス"
        required
      ></v-text-field>

      <!-- <v-text-field
        class="ml-10 mr-10"
        v-model="email"
        :rules="emailRules"
        density="compact"
        required
      /> -->

      <v-textarea
        v-model="message"
        :rules="[(v) => !!v || 'メッセージは必須です']"
        label="お問合せ内容"
        required
      ></v-textarea>

      <v-btn class="mt-5 addbobtn" height="40" width="130" rounded="pill" @click="submitForm">
        <a class="addpayment">送信</a>
      </v-btn>
    </v-form>
  </v-container>
  <v-container v-if="showSuccessMessage">
    <p>お問い合わせが完了いたしました。3日以内に担当よりご返信いたします。</p>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

const name = ref("");
const email = ref("");
const message = ref("");
const valid = ref(true);

const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];

async function submitForm() {
  if (valid.value) {
    const nameValue = name.value;
    const emailValue = email.value;
    const messageValue = message.value;
    const functions = getFunctions();
    functions.region = "asia-northeast1"; // これを追加
    // ここでフォームのデータをFirebase Cloud Functionsに送信
    const commonContactMail = httpsCallable(functions, "Common_contactMail");
    try {
      const result = await commonContactMail({
        name: nameValue,
        email: emailValue,
        message: messageValue,
      });
      console.log(result);
      showSuccessMessage.value = true;
    } catch (error) {
      console.error("Error calling function:", error);
    }
  } else {
    console.log("Form is invalid");
  }
}
const showSuccessMessage = ref(false); // 新しい変数を定義
</script>

<style scoped>
.addbobtn {
  background-color: rgb(155, 155, 155);
  /* box-shadow: rgb(155, 155, 155) 3px 3px 0px 0px; */
}
.addpayment {
  color: black;
  font-size: 16px;
}
</style>
