<script setup>
import { ref, watch } from "vue";
import TheHeader from "/src/views/TheHeader.vue";
import TheFooter from "/src/views/TheFooter.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRoute } from "vue-router";
const user = ref(null);
const auth = getAuth();
const useruid = ref(null);
onAuthStateChanged(auth, (userdata) => {
  if (userdata) {
    console.log(userdata);
    user.value = userdata;
    console.log(user.value.uid);
    console.log(typeof user.value);
    useruid.value = user.value.uid;
    console.log(useruid.value);
    // ...
  } else {
    console.log("logout");
    // User is signed out
    // ...
  }
});
//配信ページの場合ヘッダーとフッターを非表示にする
const defaultpage = ref(true);
const route = useRoute();
console.log(route.path);

watch(route, () => {
  console.log(route.path);
  if (route.path == "/main") {
    defaultpage.value = false;
    console.log(defaultpage.value);
  } else {
    defaultpage.value = true;
  }
});
</script>
<template>
  <template v-if="defaultpage">
    <v-app>
      <TheHeader />
      <v-main class="main">
        <router-view :user="user" />
      </v-main>
      <TheFooter />
    </v-app>
  </template>
  <template v-else>
    <router-view :user="user" />
  </template>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

#app {
  font-family: "Noto Sans JP";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.v-application__wrap {
  min-height: 0 !important;
}
.main {
  min-height: 100%;
}

/* reset.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background: none;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  height: 0;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button,
input {
  line-height: normal;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
legend {
  border: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
optgroup {
  font-weight: 700;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
ul {
  list-style: none;
}
*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body,
html {
  width: 100%;
  height: 100%;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  line-height: 1em;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* reset.css END */

/* .container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
} */
</style>
