<script setup>
// import { useRouter } from "vue-router";
import { collection, getDocs, getDoc } from "firebase/firestore";
import db from "../main.js";

import ToggleList from "/src/components/molecules/ToggleList.vue";
import ToggleListSelectable from "/src/components/molecules/ToggleListSelectable.vue";
import { ref, watch, computed } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from "vue-router";

const router = useRouter();

const shipping_status = ref("未引き換え");

const stepfirst = ref(true);
const stepsecond = ref(false);
const stepthird = ref(false);
const stepfourth = ref(false);
const stepfifth = ref(false);
const isLoading = ref(false);

const selectedTitle = ref(null);

const toStepSecond = (title) => {
  selectedTitle.value = title;
  stepfirst.value = false;
  stepsecond.value = true;
  getOrderNumbersByTitle(title);
};

const props = defineProps({
  user: String,
});

const eventtitle = ref([]);
const storenames = ref({});
const getOrdernumber = async () => {
  const querySnapshot = await getDocs(
    collection(db, "users", props.user.uid, "Goods_productorders")
  );
  querySnapshot.forEach(async (doc) => {
    const data = doc.data();
    const historyref = data.orders;

    const docSnap = await getDoc(historyref);

    if (docSnap.exists()) {
      const orderNumber = docSnap.id;
      const timestamp = docSnap.data().created_at;
      const eventtitleValue = docSnap.data().eventtitle;
      const storename = docSnap.data().storename;
      console.log(storename);

      const date = new Date(timestamp.seconds * 1000);
      const created_at = date.toLocaleDateString();

      console.log(orderNumber);
      console.log(timestamp);
      console.log(eventtitleValue);
      console.log(created_at);

      if (!eventtitle.value.includes(eventtitleValue)) {
        eventtitle.value.push(eventtitleValue);
        storenames.value[eventtitleValue] = storename;
        console.log(eventtitle.value);
      }
    }
  });
};

getOrdernumber();

const productData = ref([]);
const groupByProductRank = computed(() => {
  return productData.value.reduce((result, product) => {
    (result[product.product_rank_ja] || (result[product.product_rank_ja] = [])).push(product);
    return result;
  }, {});
});

const getOrderNumbersByTitle = async (title) => {
  productData.value = [];
  const querySnapshot = await getDocs(
    collection(db, "users", props.user.uid, "Goods_productorders")
  );
  querySnapshot.forEach(async (doc) => {
    const data = doc.data();
    const historyref = data.orders;

    const docSnap = await getDoc(historyref);

    if (docSnap.exists() && docSnap.data().eventtitle === title) {
      const orderNumber = docSnap.id;

      const productSnapshot = await getDocs(
        collection(db, "Goods_productorders", orderNumber, "orderproducts")
      );
      const created_atdate = docSnap.data().created_at;
      console.log(created_atdate);
      // Convert the Firestore timestamp to a JavaScript Date object
      const date = created_atdate.toDate();
      // Format the date in a human readable format with leading zeroes
      const humanReadableDate = `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()} ${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      productSnapshot.forEach(async (doc) => {
        if (doc.exists()) {
          const productDoc = await getDoc(doc.ref);
          const productInfo = productDoc.data().products;
          productInfo.forEach((product) => {
            if (product.shipping_status === shipping_status.value) {
              let humanReadableExchangeDate = ""; // 初期値を空文字列に設定

              if (product.exchange_date) {
                // Convert the Firestore timestamp to a JavaScript Date object
                const dateexchange = product.exchange_date.toDate();
                // Format the date in a human readable format with leading zeroes
                humanReadableExchangeDate = `${dateexchange.getFullYear()}/${
                  dateexchange.getMonth() + 1
                }/${dateexchange.getDate()} ${dateexchange
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${dateexchange.getMinutes().toString().padStart(2, "0")}`;
              }

              productData.value.push({
                product_name: product.product_name,
                product_rank: product.product_rank,
                productid: product.productid,
                product_rank_ja: product.product_rank_ja,
                product_image: product.product_image,
                shipping_status: product.shipping_status,
                product_identifier: product.product_identifier, // product_identifier
                orderNumber: orderNumber, // order number
                created_at: docSnap.data().created_at,
                created_at_human: humanReadableDate, // human readable date
                eventtitle: docSnap.data().eventtitle,
                exchange_date_human: humanReadableExchangeDate,
              });
            }
          });
        }
      });
    }
  });
};

watch(shipping_status, () => {
  if (stepsecond.value) {
    getOrderNumbersByTitle(selectedTitle.value);
  }
});

const selectedProducts = ref([]);
const isDeselectedAllProducts = computed(() => selectedProducts.value.length === 0);

const toStepThird = () => {
  stepsecond.value = false;
  stepthird.value = true;
  console.log(selectedProducts.value);
  // console.log(selectedProducts.value[0].orderNumber);
  // console.log(selectedProducts.value[1].orderNumber);
};

const toStepFourth = () => {
  stepthird.value = false;
  stepfourth.value = true;
};

// const addcardclose = () => {
//   // emit("getCreditCard");
//   secondstep.value = true;
//   secondstepnotwo.value = false;
// };

const comfirmgive = () => {
  isLoading.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const selectedProductsno = selectedProducts.value;
  const myCallableFunction = httpsCallable(functions, "Goods_ConfirmGive");
  myCallableFunction({ selectedProducts: selectedProductsno })
    .then((result) => {
      isLoading.value = false;
      console.log(result);
      stepfourth.value = false;
      stepfifth.value = true;
    })
    .catch((error) => {
      isLoading.value = false;
      console.log(error.message);
    });
};

const toLinkto = (url) => {
  router.push(url);
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 mb-12 title">引き換えページ</h2>
      </div>
      <div v-if="stepfirst">
        <div
          v-for="(title, index) in eventtitle"
          :key="index"
          class="box"
          @click="toStepSecond(title)"
        >
          <div class="historycontainer">
            <div class="historyitem"><span class="historylabel">イベント名</span>{{ title }}</div>
            <div class="historyitem">
              <span class="historylabel">店舗名</span> {{ storenames[title] }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="stepsecond">
        <div>
          <v-select
            label="引き換え状態: "
            v-model="shipping_status"
            :items="['未引き換え', '引き換え済']"
            id="shipping_status"
            class="mx-16"
          />
        </div>

        <div v-if="shipping_status === '未引き換え'">
          <ToggleListSelectable
            :items="groupByProductRank"
            v-model:activeItems="selectedProducts"
          />
        </div>
        <div v-else>
          <ToggleList :items="groupByProductRank" />
        </div>

        <div class="button-container my-4" v-if="shipping_status === '未引き換え'">
          <div>{{ selectedProducts.length }} 個選択中</div>
          <v-btn
            :disabled="isDeselectedAllProducts"
            class="my-2"
            height="35"
            width="100"
            rounded="pill"
            @click="toStepThird()"
            style="background-color: gray"
          >
            <a class="nextbotton">確認へ進む</a>
          </v-btn>
        </div>
      </div>
      <div v-if="stepthird">
        <h3>引き換え商品一覧</h3>
        <div v-for="(product, index) in selectedProducts" :key="index" class="product-item">
          <div class="product-info">
            <div class="product-name">商品名:{{ product.product_name }}</div>
            <div class="product-name">ランク:{{ product.product_rank_ja }}</div>
            <div class="product-name">購入日時:{{ product.created_at_human }}</div>
          </div>
          <div class="product-image">
            <img :src="product.product_image" height="100" />
          </div>
        </div>
        <div><p class="attention">※この先は店舗スタッフと行ってください</p></div>
        <v-btn
          class="mt-16 mb-16"
          height="35"
          width="150"
          rounded="pill"
          @click="toStepFourth()"
          style="background-color: gray"
        >
          <a class="nextbotton">引き換えへ進む</a>
        </v-btn>
      </div>
      <div class="unit" v-show="stepfourth">
        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-message">ローディング中<span class="dots">...</span></div>
        </div>
        <h3 class="titlesubsub">引き換え確認</h3>
        <div v-for="(product, index) in selectedProducts" :key="index" class="product-item">
          <div class="product-info">
            <div class="product-name">商品名:{{ product.product_name }}</div>
            <div class="product-name">ランク:{{ product.product_rank_ja }}</div>
            <div class="product-name">購入日時:{{ product.created_at_human }}</div>
          </div>
          <div class="product-image">
            <img :src="product.product_image" height="100" />
          </div>
        </div>
        <div><p class="attention">※この画面を店舗スタッフに渡してください</p></div>
        <div><p class="attention">※以下のボタンは店舗スタッフが確認の上押します</p></div>
        <v-btn
          class="mt-5 mb-16"
          height="35"
          width="150"
          rounded="pill"
          @click="comfirmgive()"
          style="background-color: gray"
        >
          <a class="nextbotton">提供済み</a>
        </v-btn>
      </div>
      <div class="unit" v-show="stepfifth">
        <h3 class="titlesubsub">引き換え完了</h3>
        <p class="mainwords">引き換えが完了しました。</p>
        <p class="mainwords"><a href="/shipping">他のワンショットくじを引き換えはこちら</a></p>
      </div>
    </div>
    <div class="returncontainer" @click="toLinkto('/myaccount')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title-container {
  margin: 50px 50px;
}

.historycontainer {
  border: 1px solid black;
  border-radius: 25px;
  padding: 20px;
  margin: 10px 30px;
  box-shadow: rgba(233, 233, 233, 0.9) 5px 5px 0px 0px;
}

.historyitem {
  padding: 10px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.historylabel {
  background-color: lightgray;
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 15px;
  margin-right: 10px;
  text-align: center;
  flex-shrink: 0;
  min-width: 100px;
}

.product-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product-checkbox {
  margin-right: 20px;
  margin-left: 20px;
}

.product-info {
  flex: 1;
}

.product-name {
  margin-bottom: 5px;
}

.product-rank {
  color: gray;
}

.product-image {
  margin-left: 20px;
}

.nextbotton {
  color: rgb(255 255 255);
  font-size: 12px;
}

.spacer {
  margin-bottom: 30px;
}

.unit_outline {
  border: 1px solid black;
  padding: 20px;
  margin: 20px 20px 20px 20px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  /* border-radius: 20px 20px 20px 20px; */
}

.unit_outline {
  display: flex;
  /* フレックスボックスを使う */
  flex-direction: column;
  /* フレックスボックスを縦方向にする */
  align-items: center;
  /* 中央に揃える */
  justify-content: flex-start;
  /* 上側に揃える */
}

.unit_outline-card {
  border: 1px solid black;
  padding: 30px;
  margin: 20px 20px 20px 20px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  /* border-radius: 20px 20px 20px 20px; */
}

.unit_outline-card {
  display: flex;
  /* フレックスボックスを使う */
  flex-direction: column;
  /* フレックスボックスを縦方向にする */
  align-items: center;
  /* 中央に揃える */
  justify-content: flex-start;
  /* 上側に揃える */
}

.address-content {
  text-align: left;
  /* 左揃え */
  margin-left: 0px;
  /* 左側にマージンを追加 */
}

.textaline_center {
  text-align: center;
}

.margn_bottom_reset {
  margin-bottom: 0 !important;
}

.normal {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px 0 0 0;
  padding: 10px;
}

.smallbox {
  /* border: solid 1px rgb(46, 68, 99); */
  display: inline-block;
  padding: 15px 15px 15px 15px;
  /* background-color: rgb(46, 68, 99); */
  border-radius: 20px 20px 20px 20px;
  color: white;
}

.smallboxAddress {
  border: solid 1px rgb(155, 155, 155);
  background-color: rgb(155, 155, 155);
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
}

.smallboxAddAddress {
  color: black;
  border: solid 1px rgb(217, 217, 217);
  background-color: rgb(217, 217, 217);
}

.bodyaddress {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.backgroundaddress {
  width: 100%;
  padding: 30px;
}

.fieldaddress {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.titleaddress {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.inputpostcode,
.inputtodohuken,
.inputaddress,
.inputname,
.inputnameaddress {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.erroraddress {
  color: red;
  font-size: 12px;
}

.smallboxAddressChange {
  border: solid 1px rgb(155, 155, 155);
  background-color: rgb(155, 155, 155);
}

.orderbutton {
  margin: 20px 0 20px 0;
}

.position_relative {
  position: relative;
}

.price {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 3px 0;
  /* reduce margin here */
}

.price dt {
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px;
  /* add padding-bottom here */
  margin: 5px 0 0 0;
}

.price dd {
  border-bottom: 1px solid #aaa;
  margin: 5px 0;
  /* reduce margin here */
}

.price dd:last-child {
  border-bottom: none;
}

.boxtitle {
  border: solid 1px rgb(166, 44, 44);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(166, 44, 44);
  border-radius: 20px 20px 20px 20px;
  color: white;
}

.change-button-address {
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: 10px;
  margin-right: 10px;
}

.change-button-card {
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: 10px;
  margin-right: 10px;
}

.attention {
  margin-top: 30px;
  color: red;
  font-size: 14px;
}
.titlesubsub {
  color: red;
}
.mainwords {
  margin-top: 20px;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* 全体にオーバーレイするために fixed を使用 */
  top: 0;
  left: 0;
  width: 100%; /* 画面全体の幅 */
  height: 100%; /* 画面全体の高さ */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明のグレー */
  z-index: 1000; /* 他の要素の上に表示 */
}
.loading-message {
  color: white;
  font-size: 20px;
}
.dots {
  display: inline-block;
  animation: blink 1s steps(5, start) infinite;
}

.returncontainer {
  margin: 30px 0 0 0;
}
.return {
  /* color: rgb(142 144 147); */
  font-size: 12px;
}
</style>
