<script setup>
import { toRefs, computed } from "vue";
// import { useRouter } from "vue-router";

const props = defineProps({
  goodsData: Array,
  winningRateSsr: String,
  winningRateR: String,
  winningRateA: String,
  winningRateB: String,
  winningRateC: String,
});

const { goodsData } = toRefs(props);
// const { winningRateSsr } = toRefs(props);
console.log(goodsData.value);
// console.log(winningRateSsr.value);

// const liveData = ref([{ live_status: "live", eventtitle: "タイトル" }]);

const ssrGoodsData = computed(() => {
  return goodsData.value.filter(function (goodsData) {
    return goodsData.product_rank === "ssr";
  });
});

const rGoodsData = computed(() => {
  return goodsData.value.filter(function (goodsData) {
    return goodsData.product_rank === "r";
  });
});

const aGoodsData = computed(() => {
  return goodsData.value.filter(function (goodsData) {
    return goodsData.product_rank === "a";
  });
});

const bGoodsData = computed(() => {
  return goodsData.value.filter(function (goodsData) {
    return goodsData.product_rank === "b";
  });
});

const cGoodsData = computed(() => {
  return goodsData.value.filter(function (goodsData) {
    return goodsData.product_rank === "c";
  });
});

// const dGoodsData = computed(() => {
//   return goodsData.value.filter(function (goodsData) {
//     return goodsData.product_rank === "d";
//   });
// });
// const endproductsData = computed(() => {
//   return productsData.value.filter(function (productsData) {
//     return productsData.vendor_status === "end";
//   });
// });

// const liveproductsDatainprogress = computed(() => {
//   return liveData.value.filter(function (liveData) {
//     return liveData.live_status === "inprogress";
//   });
// });

// const router = useRouter();

// const toItemlist = (productsData) => {
//   router.push(`itemlist?vendorname=${productsData.vendor_en}`);
//   // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
//   console.log(productsData);
// };
</script>
-->

<template>
  <!-- <div class="modal"> -->
  <!-- <div class="modal_title">限定グッズ一覧</div> -->
  <div class="inner">
    <div class="titleBoxSsr titleColorSsr">
      <span class="image"><img src="img/goodsListTitle_ssr.png" /></span>
      <span class="main">SS賞</span>
      <span class="sub">当選確率:{{ winningRateSsr }}%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in ssrGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr titleColorR">
      <span class="image"><img src="img/goodsListTitle_r.png" /></span>
      <span class="main">S賞</span>
      <span class="sub">当選確率:{{ winningRateR }}%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in rGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr titleColorA">
      <span class="image"><img src="img/goodsListTitle_a.png" /></span>
      <span class="main">A賞</span>
      <span class="sub">当選確率:{{ winningRateA }}%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in aGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr titleColorB">
      <span class="image"><img src="img/goodsListTitle_b.png" /></span>
      <span class="main">B賞</span>
      <span class="sub">当選確率:{{ winningRateB }}%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in bGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr titleColorC">
      <span class="image"><img src="img/goodsListTitle_c.png" /></span>
      <span class="main">C賞</span>
      <span class="sub">当選確率:{{ winningRateC }}%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in cGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div>

    <!-- <div class="titleBoxSsr titleColorD">
      <span class="image"><img src="img/goodsListTitle_d.png" /></span>
      <span class="main">D賞</span>
      <span class="sub">当選確率:40%</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine" v-for="(goods, index) in dGoodsData" :key="index">
        <img class="productpic" :src="goods.product_image" contain />
        <div class="productname">
          {{ goods.product_name }}
        </div>
      </div>
    </div> -->
    <!-- <div class="btn_conteiner unit">
      <button class="return" @click="goodslistdialogclose">閉じる</button>
    </div> -->
  </div>
  <!-- </div> -->

  <!-- <div class="titleBoxSsr">
    <div class="text">スーパーレア賞</div>
  </div>
  <div class="productcontainer">
    <div class="subproductcontaine" v-for="(goods, index) in ssrGoodsData" :key="index">
      <img class="productpic" :src="goods.product_image" contain />
      <div class="productname">
        {{ goods.product_name }}
      </div>
    </div>
  </div> -->
</template>

<style scoped>
.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus {
  border: solid 1px red;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: red;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px blue;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: blue;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.titleBoxSsr {
  background-color: rgb(242, 242, 242);
  padding: 10px 10px 10px 10px;
}

.productcontainer {
  display: flex;
  /*コレ*/
  justify-content: center; /* 子要素を水平方向に中央揃え */
  flex-wrap: wrap;
}
.subproductcontaine {
  border: 1px solid rgb(242 242 242);
  margin: 10px 2% 10px 2%;
  width: 46%;
}
.productpic {
  width: 50%;
}
.productname {
  font-size: 10px;
}

.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus {
  border: solid 1px red;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: red;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px blue;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: blue;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.titleBoxSsr {
  /* background: rgb(89, 117, 157); */
  padding: 10px 10px 10px 30px;
  margin: 0 1.5% 10px 10px;
  position: relative;
  color: #ffffff;
}
.titleColorSsr {
  background: rgb(168, 152, 122);
}
.titleColorR {
  background: rgb(133, 124, 107);
}
.titleColorA {
  background: rgb(80, 79, 78);
}
.titleColorB {
  background: rgb(123, 123, 123);
}
.titleColorC {
  background: rgb(201, 201, 201);
}
.titleColorD {
  background: rgb(217, 217, 217);
}
.titleBoxSsr .image {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  position: absolute;
  top: -7px;
  left: 0px;
}
.titleBoxSsr .image img {
  width: 160%;
  margin-right: 0px;
}
.titleBoxSsr .main {
  font-size: 1.2rem;
  margin-right: 60px;
  font-weight: bold;
}
.titleBoxSsr .sub {
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
}

.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
}
.productpic {
  width: 100%;
  margin-bottom: 0.5em;
}
.productname {
  font-size: 0.8rem;
}

.modal {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.modal .modal_title {
  width: 100%;
  padding: 12px 15px 10px 15px;
  text-align: center;
  font-size: 1rem;
  background: rgb(89, 117, 157);
  color: #ffffff;
  line-height: 1.2em;
  border-radius: 15px 15px 0 0;
  border-top: solid 1px rgb(255, 255, 255, 0.3);
}
.modal .inner {
  overflow: scroll;
  max-height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.modal .body .unit {
  margin-bottom: 15px;
}
.modal .body p {
  margin-bottom: 10px;
}

.modal .body select {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: #666 solid 2px;
  background-color: #eeeeee;
  padding: 15px 10px;
  border-radius: 0;
  font-size: 1rem;
}
.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(89, 117, 157);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(46, 68, 99);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal button.list {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  background: rgb(89, 117, 157);
  color: #ffffff;
  border-bottom: solid 1px rgb(132, 163, 206);
}
.modal button.list:active {
  background: rgb(46, 68, 99);
}
.modal .unit_outline {
  border-radius: 10px;
  border: solid 1px rgb(126, 126, 126);
  padding: 15px 10px 15px 10px;
  margin-bottom: 10px;
}

.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margn_bottom_reset {
  margin-bottom: 0 !important;
}
</style>
