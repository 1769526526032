<script setup>
import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { toRefs } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  productsData: Array,
});

const { productsData } = toRefs(props);
console.log(productsData.value);
const router = useRouter();

// const linkTo = (event_en) => {
//   console.log(event_en);
//   router.push(`itemlist?vendorname=${event_en}`);
// };
const yourClickHandlerMethod = () => {
  router.push("/guide");
};
</script>

<!-- <template>
  <carousel :items-to-show="1" wrap-around autoplay class="carousel"> -->
<!-- <Slide v-for="slide in productsData" :key="slide">
      <div class="carousel__item">
        <img class="sliderPhoto" v-bind:src="slide.image" @click="linkTo(slide.event_en)" />
      </div>
    </Slide>

    <template #addons>
      <navigation />
      <pagination />
    </template> -->
<!-- </carousel>
</template> -->
<template>
  <carousel :items-to-show="1" wrap-around autoplay class="carousel">
    <!-- ここでは v-for ディレクティブを使わず、直接画像を指定 -->
    <Slide>
      <div class="carousel__item">
        <img
          class="sliderPhoto"
          src="https://firebasestorage.googleapis.com/v0/b/rf-prd-oneshotkuji.appspot.com/o/common%2FToppic.png?alt=media&token=f9ef9546-6f02-4026-b308-29f3819d1bd4"
          @click="yourClickHandlerMethod()"
        />
      </div>
    </Slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<style scoped>
.carousel {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.carousel__item {
  margin: 0 !important;
  padding: 0 !important;
}

.sliderPhoto {
  width: 100%;
}
.carousel__slide {
  display: block !important;
}
@media screen and (min-width: 960px) {
  .carousel {
    margin: 0 250px 0 250px !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>

<!-- <template>
  <div class="carousel">
    <div class="carousel-inner">
      <div
        class="carousel-item"
        v-for="(slide, index) in productsData"
        :key="index"
        :class="{ active: index === currentSlide }"
      >
        <img :src="slide.image" alt="" />
      </div>
    </div>
    <button @click="prevSlide">Previous</button>
    <button @click="nextSlide">Next</button>
  </div>
</template>

<script setup>
import { ref, toRefs, defineProps } from "vue";

const props = defineProps({
  productsData: Array,
});
const { productsData } = toRefs(props);

const currentSlide = ref(0);

const nextSlide = () => {
  currentSlide.value = (currentSlide.value + 1) % productsData.value.length;
};

const prevSlide = () => {
  currentSlide.value =
    (currentSlide.value - 1 + productsData.value.length) % productsData.value.length;
};
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

button:first-child {
  left: 10px;
}

button:last-child {
  right: 10px;
}
</style> -->
