//
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>

<template>
  <v-footer class="footercontainer" padless>
    <v-row no-gutters>
      <a color="white" class="footer" @click="toLink('/term')">利用規約</a>
      <a color="white" class="footer" @click="toLink('/privacypolicy')">プライバシーポリシー</a>
      <a color="white" class="footer" @click="toLink('/inquiry')">お問い合わせ</a>
      <a color="white" class="footer" @click="toLink('/company')">会社概要</a>
      <a color="white" class="footer" @click="toLink('/tokushoho')">特定商取引法に関する表記</a>
      <!-- <a color="white" class="footer" @click="services">よくある質問</a> -->
      <v-col class="copyright" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Random Factory Inc.</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style scoped>
.footercontainer {
  display: flex;
  flex-wrap: wrap;
  background-color: #e85959;
  padding: 30px 0 30px 0;
}
.footer {
  color: white;
  font-size: 10px;
  width: calc(100% / 3);
}
.copyright {
  color: white;
  font-size: 10px;
  width: 100%;
  margin: 30px 0 0 0;
}
</style>
