<script setup>
import { ref, computed } from "vue";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from "vue-router";

const router = useRouter();
const email = ref("");
const nickname = ref("");
const password = ref("");
const show = ref(false);
const text = ref("");
const valid = computed(
  () =>
    emailRules.every((rule) => rule(email.value)) &&
    nicknameRules.every((rule) => rule(nickname.value)) &&
    passwordRules.every((rule) => rule(password.value))
);

const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];

const nicknameRules = [
  (v) => !!v || "ユーザー名（ニックネーム）を入力してください。",
  (v) => (v && v.length >= 2) || "ユーザー名（ニックネーム）は2文字以上である必要があります。",
  (v) => (v && v.length <= 10) || "ユーザー名（ニックネーム）は10文字以下である必要があります。",
];

const passwordRules = [
  (v) => !!v || "パスワードを入力してください",
  (v) => (v && v.length >= 6) || "パスワードは6文字以上である必要があります。",
];

const deletemessage = () => {
  text.value = null;
};

const isLoading = ref(false);
const signUp = async () => {
  isLoading.value = true;
  const auth = getAuth();

  createUserWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      console.log(userCredential.user.uid);
      updateProfile(auth.currentUser, {
        displayName: nickname.value,
      })
        .then(async () => {
          const functions = getFunctions();
          functions.region = "asia-northeast1";
          const addMessage = httpsCallable(functions, "Common_setFirstUserName");
          await addMessage({ name: nickname.value })
            .then(() => {
              router.push("/twofactorauthenticationPin");
            })
            .catch(handleError);
        })
        .catch(handleError);
    })
    .catch(handleError);
};

const handleError = (error) => {
  isLoading.value = false;

  const errorTexts = {
    "auth/email-already-in-use": "このメールアドレスは既に使われています",
    "auth/invalid-email": "メールアドレスの形式をご確認ください",
    "auth/weak-password": "パスワードが短すぎます。再度登録をしてください。",
    default: "ユーザー名、メールアドレス、パスワードを確認の上、再度登録をしてください",
  };

  text.value = errorTexts[error.code] || errorTexts.default;
  console.error(error);
};

const toLink = (url) => {
  router.push(url);
};

const toggleShow = () => {
  show.value = !show.value;
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-message">ローディング中<span class="dots">...</span></div>
      </div>
      <div>
        <h2 class="mt-12 title">ワンショットくじアカウント 新規登録</h2>
      </div>
      <div class="mt-12">
        <v-form ref="form" v-model="valid" class="form">
          <div>
            <div class="mailaddress">ユーザー名（ニックネーム）</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="nickname"
              :rules="nicknameRules"
              density="compact"
              required
              @click="deletemessage"
            />
          </div>
          <div>
            <div class="mt-5 mailaddress">メールアドレス</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
              @click="deletemessage"
            />
          </div>
          <div>
            <div class="mt-5 mailaddress">パスワード</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="password"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              density="compact"
              required
              @click:append="toggleShow"
              @click="deletemessage"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div>
            <v-btn
              :disabled="!valid"
              height="35"
              width="100"
              rounded="pill"
              @click="signUp()"
              color="rgb(202, 68, 70)"
              class="mt-5"
            >
              <a class="login">新規登録</a>
            </v-btn>
          </div>
        </v-form>
      </div>
      <div class="resetpasswordcontainer">
        <a class="resetpassword" @click="toLink('login')">
          ▶︎既にワンショットくじアカウントをお持ちの方はこちら
        </a>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 20px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* 全体にオーバーレイするために fixed を使用 */
  top: 0;
  left: 0;
  width: 100%; /* 画面全体の幅 */
  height: 100%; /* 画面全体の高さ */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明のグレー */
  z-index: 1000; /* 他の要素の上に表示 */
}
.loading-message {
  color: white;
  font-size: 20px;
}
.dots {
  display: inline-block;
  animation: blink 1s steps(5, start) infinite;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
