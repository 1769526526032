<template>
  <v-container fluid>
    <div>
      <h2 class="mt-5 mb-7 title">購入ガイド</h2>
      <p class="mb-5 mr-5 ml-5">
        ワンショットくじのご購入には、アカウントの新規登録が必要となります。
      </p>
    </div>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">1.アカウントの新規登録</h2>
          <p class="body-1 mt-4 text">
            登録をご希望のユーザーネーム（ニックネーム）、メールアドレス、パスワードを入力し、「新規登録」をクリックしてアカウントの新規登録をしてください。
          </p>
          <div>
            <p class="mt-7 resend" @click="toSignup">▶︎新規登録画面はこちら</p>
          </div>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_sign-up.png?alt=media&token=6dedadc8-3f43-4b4e-89cf-f9750ed9d649'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">2.認証コードの入力</h2>
          <p class="body-1 mt-4 text">
            ご登録のメールアドレス宛に4桁の認証コードがメールにて届きます。4桁の認証コードをご入力後、「認証完了する」をクリックしてください。
          </p>
          <p class="body-1 mt-4 text">
            ※認証メールが届かない場合は、「認証メールを再送信する」をクリックして、認証コードを取得してください。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_email%20verification.png?alt=media&token=e34478bb-48c9-417c-8939-779e90da91a5'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">3.アカウント登録の完了</h2>
          <p class="body-1 mt-4 text">
            メールアドレスの認証手続きの完了後、右の画面に遷移しましたら、アカウントの登録が完了です。これよりワンショットくじのご購入が可能となります。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_email%20verification%20completed.png?alt=media&token=6a09b177-ff68-4ae2-8abe-636dbe3152d8'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">4.購入するくじの選択</h2>
          <p class="body-1 mt-4 text">
            トップページ下部の「ワンショットくじラインナップ」をご確認の上、購入をご希望のワンショットくじを選択してください。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_1shotkuji-lineup.png?alt=media&token=4c35bbfe-f69e-435e-9bd2-52f708106b25'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">5.くじの購入手続きへ進む</h2>
          <p class="body-1 mt-4 text">
            ワンショットくじの詳細を確認し、「くじを購入する」をクリックして、購入手続きに進んでください。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_1shotkuji%20product%20details.png?alt=media&token=518d4db3-cfe0-43cc-ab72-ca306200d231'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">6.くじの数量を選択</h2>
          <p class="body-1 mt-4 text">
            購入をご希望の数量を設定します。数量の設定後、「決済を進む」をクリックしてください。
          </p>
          <p class="body-1 mt-4 text">
            ※右側のプラス（＋）マイナス（ー）ボタンもしくは下部の1回・30回・50回のボタンをクリックして数量を調整することが可能です。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_1shotkuji%20orderamount.png?alt=media&token=ebcd3038-d38b-40e4-b9ab-e2ad22182e37'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">7.クレジットカードを追加</h2>
          <p class="body-1 mt-4 text">
            ご利用のクレジットカードの情報を入力し、「カードを追加」をクリックして、カード情報を登録してください。半角英数字での入力をお願いします。
          </p>
          <p class="body-1 mt-4 text">
            ※クレジットカードのご入力は初回購入時のみとなります。2回目以降の購入時には、登録済みのカード情報にて決済することが可能です。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_creditcard-detail.png?alt=media&token=ccfc6126-d239-40a2-bd30-5ce8a378ba08'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <!-- 左側のコンテンツを上部から始める -->
      <v-col cols="12" md="6" class="d-flex align-start">
        <div class="ml-5 mr-5">
          <h2 class="headline mt-5 mb-1">8.注文の確定</h2>
          <p class="body-1 mt-4 text">
            ご年齢を確認するため、生年月日を選択してください。生年月日の選択後、購入するくじの数量、合計金額、クレジットカード情報をご確認上、「注文を確定する」をクリックしてください。
          </p>
          <p class="body-1 mt-4 text">
            ※「生年月日を保存する」にチェックを入れることにより、次回以降は生年月日のご入力が不要となります。
          </p>
        </div>
      </v-col>

      <!-- 右側の画像 -->
      <v-col cols="12" md="6">
        <v-img
          :src="'https://firebasestorage.googleapis.com/v0/b/rf-dev-oneshotkuji.appspot.com/o/Guide_payment.png?alt=media&token=b63c4eb0-d3c9-425f-a142-6c81f32a0ee8'"
          aspect-ratio="1.7"
          class="grey lighten-2"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const toSignup = () => {
  router.push("/signup");
};
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
}

.body-1 {
  font-size: 16px;
}
.resend {
  color: rgb(202, 68, 70);
  cursor: pointer;
}
.text {
  text-align: left;
}
/* スマートフォンビューでのテキストの位置を調整 */
@media only screen and (max-width: 960px) {
  .d-flex.align-start {
    align-items: center; /* 中央揃えにする */
  }
}
</style>
