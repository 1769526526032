import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TheHome from "../views/TheHome.vue";
import AboutUs from "../views/AboutUs.vue";
import ItemList from "../views/ItemList.vue";
import TheLogin from "../views/TheLogin.vue";
import MyCollections from "../views/MyCollections.vue";
import ResetPassword from "../views/ResetPassword.vue";
import TheSignup from "../views/TheSignup.vue";
import MyAccount from "../views/MyAccount.vue";
import EditProfile from "../views/EditProfile.vue";
import EditPaymentInfo from "../views/EditPaymentInfo.vue";
import MyAddress from "../views/MyAddress.vue";
import OrderHistory from "../views/OrderHistory.vue";
import OrderHistoryGoods from "../views/OrderHistoryGoods.vue";
import OrderHistoryGoodsDetail from "../views/OrderHistoryGoodsDetail.vue";
import OrderGoods from "../views/OrderGoods.vue";
import TheShipping from "../views/TheShipping.vue";
import OrderPayment from "../views/OrderPayment.vue";
import ShippingHistory from "../views/ShippingHistory.vue";
import SetNewPassword from "../views/SetNewPassword.vue";
import ShippingHistoryGoodsDetail from "../views/ShippingHistoryGoodsDetail.vue";
import TheTokushoho from "../views/TheTokushoho.vue";
import TestGmo from "../views/TestGmo.vue";
import TwoFactorAuthentication from "../views/TwoFactorAuthentication.vue";
import TwoFactorAuthenticationPin from "../views/TwoFactorAuthenticationPin.vue";
import TwoFactorAuthenticationDone from "../views/TwoFactorAuthenticationDone.vue";
import ChangePasswordDone from "../views/ChangePasswordDone.vue";
import BeforeTwoFactorAuthentication from "../views/BeforeTwoFactorAuthentication.vue";
import TheInquiry from "../views/TheInquiry.vue";
import TheCompany from "../views/TheCompany.vue";
import TheTerm from "../views/TheTerm.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TheGuide from "../views/TheGuide.vue";

function checkAuthBeforeEnter(to, from, next) {
  let auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      to.meta.user = user;
      console.log(user);
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
}

function checkAuthBeforeEnterandtwofactorauthentication(to, from, next) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      to.meta.user = user;

      if (!user.emailVerified) {
        // ユーザーのメールアドレスが未認証の場合、指定のルートにリダイレクト
        next({
          path: "/twofactorauthenticationpin",
          query: { redirect: to.fullPath },
        });
      } else {
        // メールアドレスが認証済みの場合、次のミドルウェア/ルートに進む
        next();
      }
    } else {
      // ユーザーが認証されていない場合、ログインページにリダイレクト
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
}

const routes = [
  {
    path: "/",
    name: "home",
    component: TheHome,
    meta: {
      title: "ワンショットくじ",
      description: "ワンショットくじは、貴重なお酒をワンショットお店で飲めるくじのサービスです。",
    },
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/itemlist",
    name: "itemlist",
    component: ItemList,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/login",
    name: "login",
    component: TheLogin,
  },

  {
    path: "/resetpassword",
    name: "resetpassword",
    component: ResetPassword,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/signup",
    name: "signup",
    component: TheSignup,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/setnewpassword",
    name: "setnewpassword",
    component: SetNewPassword,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/tokushoho",
    name: "tokushoho",
    component: TheTokushoho,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/myaccount",
    name: "myaccount",
    component: MyAccount,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: EditProfile,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/editpaymentinfo",
    name: "editpaymentinfo",
    component: EditPaymentInfo,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/myaddress",
    name: "myaddress",
    component: MyAddress,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistory",
    name: "orderhistory",
    component: OrderHistory,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistorygoods",
    name: "orderhistorygoods",
    component: OrderHistoryGoods,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderhistorygoodsdetail",
    name: "orderhistorygoodsdetail",
    component: OrderHistoryGoodsDetail,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/ordergoods",
    name: "ordergoods",
    component: OrderGoods,
    beforeEnter: checkAuthBeforeEnterandtwofactorauthentication,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/orderpayment",
    name: "orderpayment",
    component: OrderPayment,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/shipping",
    name: "shipping",
    component: TheShipping,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/shippinghistory",
    name: "shippinghistory",
    component: ShippingHistory,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/shippinghistorygoodsdetail",
    name: "shippinghistorygoodsdetail",
    component: ShippingHistoryGoodsDetail,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/testgmo",
    name: "testgmo",
    component: TestGmo,
  },
  {
    path: "/twofactorauthentication",
    name: "twofactorauthentication",
    component: TwoFactorAuthentication,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/twofactorauthenticationpin",
    name: "twofactorauthenticationpin",
    component: TwoFactorAuthenticationPin,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/twofactorauthenticationdone",
    name: "twofactorauthenticationdone",
    component: TwoFactorAuthenticationDone,
  },
  {
    path: "/changepassworddone",
    name: "changepassworddone",
    component: ChangePasswordDone,
  },
  {
    path: "/beforetwofactorauthentication",
    name: "beforetwofactorauthentication",
    component: BeforeTwoFactorAuthentication,
    // beforeEnter: checkAuthBeforeEnter,
    // props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/mycollections",
    name: "mycollections",
    component: MyCollections,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: TheInquiry,
  },
  {
    path: "/company",
    name: "company",
    component: TheCompany,
  },
  {
    path: "/term",
    name: "term",
    component: TheTerm,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/guide",
    name: "guide",
    component: TheGuide,
  },
];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
