<script setup>
import { ref, toRefs, computed } from "vue";
import { collection, deleteDoc, doc, query, onSnapshot } from "firebase/firestore";
import { useRouter } from "vue-router";
import AddAddress from "/src/components/molecules/AddAddress.vue";
// import EditAddress from "/src/components/molecules/EditAddress.vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import db from "../main.js"; //追加
const props = defineProps({
  user: String,
});
const { user } = toRefs(props);
// const { useruid } = toRefs(props);
// const { uid } = toRefs(props);
console.log(user.value.uid);

const addresses = ref([]);

const qr = query(collection(db, "users", user.value.uid, "addresses"));

onSnapshot(qr, (querySnapshot) => {
  querySnapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      // 追加時
      // 変更のタイプ
      console.log(change.type);
      // 対象のドキュメント
      console.log(change.doc.id);
      console.log(change.doc.data(), null, 2);
      // refに対する変更後のindex。削除時は-1
      console.log(change.newIndex);
      // refに対する変更前のindex。追加時は-1
      console.log(change.oldIndex);

      addresses.value.push(change.doc.data());
      console.log(addresses.value);
    } else if (change.type === "modified") {
      // 変更時
      // 変更のタイプ
      console.log(change.type);
      console.log(change.doc.id);
      // 対象のドキュメント
      console.log(change.doc.data(), null, 2);
      addresses.value.push(change.doc.data());
      console.log(addresses.value);
    } else if (change.type === "removed") {
      // 削除時
      //ここでは削除の際の連想配列からのデータ削除は別のファンクションでやっている
      // 変更のタイプ
      console.log(change.type);
      // 対象のドキュメント
      console.log(change.doc.data(), null, 2);
    }
  });

  // for (let change of querySnapshot.docChanges()) {
  //   if (change.type === "added") {
  //     // データが追加された時
  //     console.log(querySnapshot.data(), " => ");
  //     const data = doc.data();
  //     console.log(data);
  //     addresses.value.push(data);
  //     console.log(addresses.value);
  //   } else if (change.type === "modified") {
  //     // データが変更された時
  //   } else if (change.type === "removed") {
  //     // データが削除された時
  //   }
  // }
});

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};

//編集
// const editAddress = async (id, postNumber, address01, address02, name, phoneNumber, index) => {
//   opendialogedit();
//   //このIDはアドレスのドキュメントID
//   console.log(id);
//   console.log(index);
// };

const deleteAddress = async (id, index) => {
  //このIDはアドレスのドキュメントID
  console.log(id);
  await deleteDoc(doc(db, "users", user.value.uid, "addresses", id))
    .then(() => {
      // eslint-disable-next-line no-console
      console.log("アドレスを削除しました");
      addresses.value.splice(index);
      console.log(addresses.value);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};
const dialog = ref(false);
const opendialog = () => {
  console.log("aaaa");
  dialog.value = true;
};

// const dialogedit = ref(false);
// const opendialogedit = () => {
//   console.log("aaaa");
//   dialogedit.value = true;
// };

const sendaddress = async (postcode, address1, address2, name, phoneno) => {
  console.log(postcode.value);
  //refを消す
  const postcodeno = postcode.value;
  const address1no = address1.value;
  const address2no = address2.value;
  const nameno = name.value;
  const phonenono = phoneno.value;
  console.log(nameno);
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_Addaddress");
  console.log(address1no);
  await addMessage({
    postcode: postcodeno,
    address1: address1no,
    address2: address2no,
    name: nameno,
    phoneno: phonenono,
  }).then((res) => {
    console.log(res);
    dialog.value = false;
  });
};

const addaddressclose = () => {
  dialog.value = false;
};

//デフォルトアドレスの判定
const defaultAddress = ref(false);
const getDefaultAddress = () => {
  onSnapshot(doc(db, "users", user.value.uid, "sub_accounts", "sub_accounts"), (doc) => {
    console.log("Current data: ", doc.data());
    defaultAddress.value = doc.data().default_address;
  });
};
getDefaultAddress();

const setDefaulAddress = async (id) => {
  console.log(id);
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_SetDefaultAddress");
  await addMessage({
    defaultAddressId: id,
  }).then((res) => {
    console.log(res);
    // dialog.value = false;
    // location.reload();
  });
};

//デフォルトを一番上に表示するためにソートする
let sortedAddresses = computed(() => {
  return addresses.value.slice().sort((a, b) => {
    if (a.addressId === defaultAddress.value) return -1;
    if (b.addressId === defaultAddress.value) return 1;
    return 0;
  });
});
</script>
<template>
  <v-app>
    <v-dialog v-model="dialog" width="unset"
      ><AddAddress :user="user" @sendaddress="sendaddress" @addaddressclose="addaddressclose"
    /></v-dialog>
    <!-- <v-dialog v-model="dialogedit" width="unset"
      ><EditAddress
        :user="user"
        :id="id"
        :postNumber="postNumber"
        :address01="ddress01"
        :address02="address02"
        :name="name"
        :phoneNumber="phoneNumber"
        :inde="index"
        @sendaddress="sendaddress"
        @addaddressclose="addaddressclose"
    /></v-dialog> -->
    <div>
      <h2 class="mt-12 title">お届け先情報</h2>
    </div>

    <div v-for="(address, index) in sortedAddresses" :key="index">
      <div class="box">
        <div class="postcode">〒 {{ address.postNumber }}</div>
        <div class="address">住所: {{ address.address01 }}</div>
        <div class="subaddress">マンション名等: {{ address.address02 }}</div>
        <div class="addressname">名前: {{ address.name }}</div>
        <div class="phoneno-flex">
          <div class="phoneno">電話番号: {{ address.phoneNumber }}</div>
          <div class="default" v-if="address.addressId == defaultAddress">デフォルト</div>
          <div
            class="default"
            v-if="address.addressId !== defaultAddress"
            @click="setDefaulAddress(address.addressId)"
          >
            デフォルト住所にする
          </div>
        </div>
      </div>
      <!-- <div class="edit"><v-icon>mdi-pencil</v-icon>編集</div> -->
      <div
        class="delete"
        v-if="address.addressId !== defaultAddress"
        @click="deleteAddress(address.addressId, index)"
      >
        ー削除
      </div>
    </div>

    <div>
      <v-btn
        variant="outlined"
        class="mt-5 addbobtn"
        height="25"
        width="130"
        rounded="pill"
        @click="opendialog()"
      >
        <a class="addpayment">+お届け先情報の追加</a>
      </v-btn>
    </div>
    <div class="returncontainer" @click="toLink('myaccount')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title {
  font-size: 14px;
}
.box {
  border-radius: 15px;
  border: 1px solid #000;
  background-color: white;
  width: 300px;
  height: 120px;
  padding: 0.5em 1em;
  margin: 2em 0;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.postcode {
  font-size: 10px;
}
.address {
  font-size: 10px;
  margin-top: 5px;
}
.subaddress {
  font-size: 10px;
  margin-top: 5px;
}
.addressname {
  font-size: 10px;
  margin-top: 5px;
}
.phoneno-flex {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 5px;
}

.phoneno {
  margin: 0;
}

.default {
  margin: 0;
}
.addpayment {
  color: rgb(255 255 255);
  font-size: 10px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.edit {
  font-size: 10px;
  margin: -30px 0px 0px 180px;
}
.delete {
  font-size: 10px;
  color: red;
  margin: -30px 0px 0px 260px;
}
.btn {
  background-color: rgb(226, 226, 226);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.addbobtn {
  background-color: rgb(155, 155, 155);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
