<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>

<template>
  <header class="app-bar">
    <!-- <div class="leftspace"></div> -->
    <button class="account-btn account-btn-desktop" @click="toLink('/mycollections')">
      <svg
        version="1.1"
        id="_x32_"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="width: width: 32px; height: 32px; opacity: 1; "
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M480.025,128.018c-9.342-25.698-24.63-50.058-43.897-70.696c-19.286-20.611-42.592-37.55-68.421-47.757
        C351.561,3.193,335.697,0.007,320.632,0c-17.025-0.013-33.086,4.143-46.67,12.357l-0.603,0.374L62.033,165.372
        c-12.331,7.716-22.434,18.769-29.218,32.188c-7.008,13.799-10.607,29.946-10.6,47.607v171.377L264.253,512l7.592-5.487
        l217.941-157.427V180.6C489.779,162.927,486.226,145.161,480.025,128.018z M249.464,333.851v135.524l-193.023-76.13V245.167
        c0.007-13.072,2.642-23.732,6.896-32.109c4.288-8.391,10.116-14.613,17.451-19.071l0.584-0.354l14.474-10.456
        c4.353-0.905,8.988-1.396,13.938-1.403c10.876,0,23.114,2.32,36.258,7.512c14.016,5.52,27.586,13.728,40.016,23.889
        c18.644,15.229,34.666,34.844,45.856,55.854c11.223,21.01,17.569,43.352,17.549,63.871h12.358l0.682,0.951H249.464z
        M264.699,281.944c-9.08-25.036-24.046-48.885-42.894-69.07c-18.854-20.165-41.635-36.692-66.69-46.584
        c-8.706-3.436-17.287-5.868-25.672-7.382L292.089,41.425c7.709-4.53,17.189-7.185,28.543-7.198
        c10.194-0.007,21.85,2.176,34.516,7.178h0.006c13.492,5.323,26.655,13.269,38.737,23.148
        c18.146,14.803,33.788,33.971,44.683,54.412c5.448,10.213,9.722,20.748,12.606,31.159c2.602,9.401,4.064,18.71,4.32,27.632
        l-183.315,132.41C270.58,300.68,268.062,291.226,264.699,281.944z M455.56,331.59L274.185,462.604V339.227l77.015-55.631v60.875
        l46.669-33.283v-61.301l57.69-41.667V331.59z"
            style="fill: rgb(255, 255, 255)"
          ></path>
        </g>
      </svg>
    </button>
    <button class="account-btn account-btn-mobile" @click="toLink('/mycollections')">
      <svg
        version="1.1"
        id="_x32_"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="width: width: 32px; height: 32px; opacity: 1; "
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M480.025,128.018c-9.342-25.698-24.63-50.058-43.897-70.696c-19.286-20.611-42.592-37.55-68.421-47.757
        C351.561,3.193,335.697,0.007,320.632,0c-17.025-0.013-33.086,4.143-46.67,12.357l-0.603,0.374L62.033,165.372
        c-12.331,7.716-22.434,18.769-29.218,32.188c-7.008,13.799-10.607,29.946-10.6,47.607v171.377L264.253,512l7.592-5.487
        l217.941-157.427V180.6C489.779,162.927,486.226,145.161,480.025,128.018z M249.464,333.851v135.524l-193.023-76.13V245.167
        c0.007-13.072,2.642-23.732,6.896-32.109c4.288-8.391,10.116-14.613,17.451-19.071l0.584-0.354l14.474-10.456
        c4.353-0.905,8.988-1.396,13.938-1.403c10.876,0,23.114,2.32,36.258,7.512c14.016,5.52,27.586,13.728,40.016,23.889
        c18.644,15.229,34.666,34.844,45.856,55.854c11.223,21.01,17.569,43.352,17.549,63.871h12.358l0.682,0.951H249.464z
        M264.699,281.944c-9.08-25.036-24.046-48.885-42.894-69.07c-18.854-20.165-41.635-36.692-66.69-46.584
        c-8.706-3.436-17.287-5.868-25.672-7.382L292.089,41.425c7.709-4.53,17.189-7.185,28.543-7.198
        c10.194-0.007,21.85,2.176,34.516,7.178h0.006c13.492,5.323,26.655,13.269,38.737,23.148
        c18.146,14.803,33.788,33.971,44.683,54.412c5.448,10.213,9.722,20.748,12.606,31.159c2.602,9.401,4.064,18.71,4.32,27.632
        l-183.315,132.41C270.58,300.68,268.062,291.226,264.699,281.944z M455.56,331.59L274.185,462.604V339.227l77.015-55.631v60.875
        l46.669-33.283v-61.301l57.69-41.667V331.59z"
            style="fill: rgb(255, 255, 255)"
          ></path>
        </g>
      </svg>
    </button>
    <h1 class="logo-title" @click="toLink('/')">
      <!-- PCの表示 -->
      <div class="logo-desktop cursor">
        <img
          src="https://storage.googleapis.com/rf-prd-oneshotkuji.appspot.com/common/oneshotkujilogo.png"
          alt="One Shotくじ"
        />
      </div>
      <!-- スマホの表示 -->
      <div class="logo-mobile">
        <img
          src="https://storage.googleapis.com/rf-prd-oneshotkuji.appspot.com/common/oneshotkujilogo.png"
          alt="One Shotくじ"
        />
      </div>
    </h1>
    <!-- PCの表示 -->
    <button class="account-btn account-btn-desktop" @click="toLink('/myaccount')">
      <svg style="width: 32px; height: 32px" viewBox="0 0 24 24">
        <path
          fill="white"
          d="M12,14.5C15.31,14.5 18,16.36 18,18.5V20H6V18.5C6,16.36 8.69,14.5 12,14.5M12,12C14.21,12 16,10.21 16,8C16,5.79 14.21,4 12,4C9.79,4 8,5.79 8,8C8,10.21 9.79,12 12,12Z"
        />
      </svg>
    </button>

    <!-- スマホの表示 -->
    <button class="account-btn account-btn-mobile" @click="toLink('/myaccount')">
      <svg style="width: 32px; height: 32px" viewBox="0 0 24 24">
        <path
          fill="white"
          d="M12,14.5C15.31,14.5 18,16.36 18,18.5V20H6V18.5C6,16.36 8.69,14.5 12,14.5M12,12C14.21,12 16,10.21 16,8C16,5.79 14.21,4 12,4C9.79,4 8,5.79 8,8C8,10.21 9.79,12 12,12Z"
        />
      </svg>
    </button>
  </header>
</template>

<style scoped>
.logo-title {
  font-family: "Yuji Mai", sans-serif;
  color: white;
}
.app-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: rgb(202, 68, 70);
  border-bottom: 1px solid gray;
  min-height: 110px;
}

.logo {
  max-height: 80px;
}

.cursor {
  cursor: pointer;
}

.logo-desktop,
.account-btn-desktop {
  display: none;
}

.leftspace {
  width: 24px;
}

@media screen and (min-width: 600px) {
  .logo-desktop,
  .account-btn-desktop {
    display: block;
  }

  .logo-mobile,
  .account-btn-mobile {
    display: none;
  }
}
/* PC用のロゴのサイズ調整 */
.logo-desktop img {
  max-width: 400px; /* 最大幅を150pxに設定 */
  height: auto; /* 高さを自動調整 */
  right: 300px;
}

/* スマホ用のロゴのサイズ調整 */
.logo-mobile img {
  max-width: 250px; /* 最大幅を100pxに設定 */
  height: auto; /* 高さを自動調整 */
}

/* PC表示用のスタイル */
.account-btn-desktop {
  /* PC表示でのSVGの位置を調整 */
  top: 0; /* 上からの位置 */
  right: 0; /* 右からの位置 */
  /* その他のPC固有のスタイルがあればここに追加 */
}

/* スマホ表示用のスタイル */
.account-btn-mobile {
  /* スマホ表示でのSVGの位置を調整 */
  top: 0; /* 上からの位置 */
  right: 0; /* 右からの位置 */
  /* その他のスマホ固有のスタイルがあればここに追加 */
}
</style>
