<script setup>
import { ref } from "vue";
import { onSnapshot, doc, setDoc, collection } from "firebase/firestore";
import BannerPicture from "/src/components/atoms/BannerPicture.vue";
import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
import SellInfo from "/src/components/atoms/SellInfo.vue";
import db from "../main.js"; //追加
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
const props = defineProps({
  user: String,
});
const route = useRoute();
const router = useRouter();
const vendorname = route.query.vendorname;
console.log(vendorname);
const vtuber_image = ref(null);
const event_title = ref(null);
// const product_image = ref(null);
// const cardsData = ref([]);
const eventdatestart = ref(null);
const eventdateend = ref(null);
const price = ref(null);
const shippingdate = ref(null);
const shop_name = ref(null);
const googlemapURL = ref(null);
//イベント情報呼び出し
// const eventData = ref([]);
const live_status = ref("live");

const eventtitle = ref(null);
const getEventData = async () => {
  // const docRef = doc(db, "Goods_products", vendorname);
  onSnapshot(doc(db, "Goods_products", vendorname), (doc) => {
    console.log("Current data: ", doc.data());
    // console.log("Document data:", docSnap.data());
    const data = doc.data();
    console.log(data);

    vtuber_image.value = data.image;
    console.log(vtuber_image.value);
    event_title.value = data.eventtitle;
    shop_name.value = data.storename;
    googlemapURL.value = data.googlemapURL;
    // eventdatestart.value = eventdatestartdateafter;
    // eventdateend.value = eventdateenddateafter;
    price.value = data.price;
    shippingdate.value = data.shipping_date;
    live_status.value = data.live_status;
    eventtitle.value = data.eventtitle;
    // live_status.value が true ではない場合にリダイレクト
    // if (live_status.value !== "live") {
    //   router.push(`itemlist?vendorname=${vendorname}`); // リダイレクト
    // }
  });
};

getEventData();

//在庫確認のために呼び出し
const stockCheck = ref(true);
const getProductsData = async () => {
  const query = collection(db, "Goods_products", vendorname, "products");

  // リアルタイムでの更新リスナーを設定
  onSnapshot(query, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(data);

      // 在庫が0の場合、強制リダイレクト設定
      if (data.stock_quantity <= 0) {
        // router.push(`itemlist?vendorname=${vendorname}`); // リダイレクト
        stockCheck.value = false;
      }
    });
  });
};

getProductsData();

const selectnumber = ref(10);
const confirmgoods = async () => {
  console.log(selectnumber.value);
  console.log(route.query.vendorname);
  console.log("oooooxxx");
  console.log(props.user);
  if (props.user) {
    console.log("zzz");
    console.log(selectnumber.value);
    console.log(props.user);
    //カートへ書き込み
    await setDoc(
      doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems"),
      {
        quantity: selectnumber.value,
        vendorname: route.query.vendorname,
        eventtitle: eventtitle.value,
        storename: shop_name.value,
      },
      {
        merge: false,
      }
    );
  }
  router.push(`orderpayment?vendorname=${vendorname}`);
};

//＋ーで商品数を変更
const incrementSelectNumber = () => {
  // selectnumberの最大値を50に設定
  const maxValue = 50;
  if (selectnumber.value < maxValue) {
    selectnumber.value++;
  }
};

const decrementSelectNumber = () => {
  if (selectnumber.value > 1) {
    selectnumber.value--;
  }
};
const setSelectNumber = (value) => {
  // 最大値を50に設定
  const maxValue = 50;

  // 与えられた値が最大値より大きい場合、値を最大値に制限
  if (value > maxValue) {
    selectnumber.value = maxValue;
  } else {
    selectnumber.value = value;
  }
};
</script>

<template>
  <v-app>
    <h2 class="title">購入手続き</h2>
    <p class="banner"><BannerPicture :vtuber_image="vtuber_image" /></p>
    <CampaignTitleBar :event_title="event_title" />
    <h3 class="title">数量選択</h3>
    <div class="spacer"></div>
    <!-- 追加 -->
    <div class="body">
      <div class="unit">
        <div class="number-selection">
          <div class="control-group">
            <!-- 追加 -->
            <p class="number-display">{{ selectnumber }}</p>
            <div class="quantity-buttons">
              <button class="quantity-button" @click="decrementSelectNumber()">-</button>
              <button class="quantity-button" @click="incrementSelectNumber()">+</button>
            </div>
          </div>
          <div class="preset-buttons">
            <button class="preset-button" @click="setSelectNumber(1)">1回</button>
            <button class="preset-button" @click="setSelectNumber(30)">30回</button>
            <button class="preset-button" @click="setSelectNumber(50)">50回</button>
            <!-- <button class="preset-button" @click="setSelectNumber(100)">100</button> -->
          </div>
        </div>
        <p class="textaline_right"></p>
      </div>
      <p mt-10>※最大購入可能数は50回となります。</p>
    </div>
    <h3
      v-if="live_status === 'live' && stockCheck === true"
      class="title boxtitle cursor"
      @click="confirmgoods"
    >
      決済へ進む
    </h3>
    <h3 v-else class="title boxtitleend cursor">このくじは終了しました</h3>
    <SellInfo
      :eventdatestart="eventdatestart"
      :eventdateend="eventdateend"
      :price="price"
      :shippingdate="shippingdate"
      :shop_name="shop_name"
      :googlemapURL="googlemapURL"
    />

    <p class="text_age">
      ※２０歳未満の飲酒は法律で禁止されています。<br />※２０歳未満に対してのお酒の販売は致しません。
    </p>
  </v-app>
</template>

<style scoped>
.title {
  margin: 20px 0 0 0;
}
/* 追加 */
.spacer {
  margin-bottom: 30px;
}

.title {
  margin: 20px 0 0 0;
}

.banner {
  margin: 20px 0 0 0;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center; /* 追加 */
  justify-content: center; /* 追加 */
  margin-bottom: 40px;
}

.quantity-buttons {
  display: flex;
  margin-left: 10px;
}

.quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
}

.quantity-button:focus {
  outline: none;
}

.textaline_right {
  text-align: right;
}

.number-display {
  border: 3px solid black;
  padding: 10px;
  display: inline-block;
  text-align: center;
  width: 100px;
  height: 50px;
}

.quantity-button {
  margin: 0 5px; /* 0は上下のマージン、10pxは左右のマージン */
  width: 50px; /* ボタンの幅 */
  height: 50px; /* ボタンの高さ */
}
.number-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.control-group {
  /* 追加 */
  display: flex;
  justify-content: flex-start;
}

.quantity-buttons {
  display: flex;
}

.preset-buttons {
  display: flex;
  justify-content: flex-start;
}
.preset-button {
  border: 2px solid #000000; /* ボーダーの太さ、スタイル、色 */
  width: 50px;
  padding: 5px; /* ボタン内のスペース */
  margin: 5px; /* ボタン間のスペース */
}
.boxtitle {
  border: solid 1px rgb(202, 68, 70);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(202, 68, 70);
  border-radius: 20px 20px 20px 20px;
  color: white;
  width: 173px;
  height: 49px;
  text-align: center;
  margin: auto; /* ボックスタイトルを中央に配置 */
  display: flex; /* 追加 */
  justify-content: center; /* 追加 */
  align-items: center; /* 追加 */
  font-size: 15px;
}
.boxtitleend {
  border: solid 1px rgb(242, 242, 242);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 20px 20px;
  color: black;
  width: 200px;
  height: 49px;
  text-align: center;
  margin: auto; /* ボックスタイトルを中央に配置 */
  display: flex; /* 追加 */
  justify-content: center; /* 追加 */
  align-items: center; /* 追加 */
  font-size: 15px;
}
.cursor {
  cursor: pointer;
}
.text_age {
  font-size: 13px;
  text-align: center;
  color: red;
  margin: 25px 0 0 15px;
}
</style>
