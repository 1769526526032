<script setup>
import { toRefs, computed } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  eventData: Array,
});

const { eventData } = toRefs(props);
console.log("aaaaa");
console.log(eventData.value);
const router = useRouter();
const eventproductsData = computed(() => {
  return eventData.value.filter(function (eventData) {
    return eventData.live_status === "live";
  });
});

// const endproductsData = computed(() => {
//   return productsData.value.filter(function (productsData) {
//     return productsData.vendor_status === "end";
//   });
// });

const eventproductsDatainprogress = computed(() => {
  return eventData.value.filter(function (eventData) {
    return eventData.live_status === "inprogress";
  });
});

// const router = useRouter();

const toItemlist = (productsData) => {
  router.push(`itemlist?vendorname=${productsData.event_en}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
  console.log(productsData);
};
// const toMain = (productsData) => {
//   router.push(`main?vendorname=${productsData.vendor_en}`);
//   // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
//   console.log(productsData);
// };
</script>
<template>
  <div class="main">
    <v-row>
      <v-col v-for="(productsData, index) in eventproductsData" :key="index" cols="12" md="4">
        <div class="maincontainer">
          <v-img class="cursor" :src="productsData.image" @click="toItemlist(productsData)" />

          <v-card>
            <div class="livecontainer cursor" @click="toItemlist(productsData)">
              <div class="livestatus"><p>販売中</p></div>
              <h3 class="title">{{ productsData.eventtitle }}</h3>
              <p class="title">店舗名：{{ productsData.storename }}</p>
              <p class="title">販売価格：1回{{ productsData.price }}円（税込）</p>
              <!-- <p class="time">
                <v-icon size="20"> mdi-timer </v-icon>{{ productsData.livedateend }}まで
              </p> -->
            </div>
          </v-card>
        </div>
      </v-col>

      <v-col
        v-for="(productsData, index) in eventproductsDatainprogress"
        :key="index"
        cols="12"
        md="4"
      >
        <div class="maincontainer">
          <v-img class="cursor" :src="productsData.image" @click="toItemlist(productsData)" />

          <v-card>
            <!-- <div class="iconcontainer">
              <div class="icon"><img :src="productsData.iconURL" /></div>
              <p>{{ productsData.vendor_ja }}</p>
            </div> -->
            <div class="livecontainer cursor" @click="toItemlist(productsData)">
              <div class="livestatusinprogress"><p>販売予定</p></div>
              <h3 class="title">{{ productsData.eventtitle }}</h3>
              <p class="title">店舗名：{{ productsData.storename }}</p>
              <p class="title">販売価格：1回{{ productsData.price }}円（税込）</p>
              <!-- 
              <p class="time">
                <v-icon size="20"> mdi-timer </v-icon>{{ productsData.livedatestart }}から
              </p> -->
              <p class="time">
                <v-icon size="20"> mdi-timer </v-icon>{{ productsData.livedateend }}まで
              </p>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.maincontainer {
  margin: 10px 0 10px 0;
}

.livecontainer {
  /* border: solid 1px #454545; */
  display: flex; /* Add flexbox to ensure consistent layout */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align children to the start */
}

.livestatus,
.livestatusinprogress {
  display: inline-block;
  margin: 15px 0 5px 20px;
  padding: 5px;
  font-size: 12px;
}

.livestatus {
  border: solid 1px rgb(166, 44, 44);
  background-color: rgb(166, 44, 44);
  color: white;
}

.livestatusinprogress {
  border: solid 1px rgb(89, 117, 157);
  background-color: rgb(89, 117, 157);
  color: white;
}

.title {
  font-size: 17px;
  margin: 5px 0 5px 20px;
  text-align: left;
}

.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.cursor {
  cursor: pointer;
}
@media (min-width: 960px) {
  .main {
    margin: 0pc 10px 0 10px;
  }
}
</style>
