<script setup>
// eslint-disable-next-line no-undef
import { ref, onMounted } from "vue";
import { stripe } from "/src/stripe.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const emit = defineEmits(["addcardclose", "addcardcloseandload"]);
const errortext = ref(null);
const error = ref(false);

const closeerrormessage = () => {
  error.value = false;
};
const elements = stripe.elements();

const elementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 500,
    // fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
    fontSize: "10px",
    fontSmoothing: "antialiased",
    "::placeholder": {
      color: "#CFD7DF",
    },
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
  invalid: {
    color: "#E25950",
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};
const elementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid",
};
const cardNumber = ref(null);
onMounted(() => {
  console.log("on mount");
  cardNumber.value = elements.create("cardNumber", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardNumber.value.mount("#card-number");

  // // カードの有効期限
  const cardExpiry = elements.create("cardExpiry", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardExpiry.mount("#card-expiry");

  // // カードのCVC入力
  const cardCvc = elements.create("cardCvc", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardCvc.mount("#card-cvc");
});

// トークンを作成
// cardNumberのみを指定する

const addcardfun = () => {
  stripe.createToken(cardNumber.value, {}).then(async function (result) {
    if (result.error) {
      // eslint-disable-next-line no-console
      console.log(result.error.message);
      this.text = result.error.message;
      this.snackbar = true;
      this.isLoading = false;
    } else {
      // await setStripeCardInfo(result.token, this.prevRoute.name);
      // eslint-disable-next-line no-console

      const functions = getFunctions();
      functions.region = "asia-northeast1"; // これを追加
      const addMessage = httpsCallable(functions, "Common_addcards");
      await addMessage(result.token)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.log(res);
          // エラーが起きた
          if (res.data.error) {
            // eslint-disable-next-line no-console
            console.log(res.data.error.code);
            if (res.data.error.code === "card_declined") {
              errortext.value = "カード登録に失敗しました";
              error.value = true;
            } else if (res.data.error.code === "incorrect_cvc") {
              errortext.value = "セキュリティーコードが間違っています";
              error.value = true;
            } else if (res.data.error.code === "incorrect_expiry") {
              errortext.value = "有効期限が間違っています";
              error.value = true;
            } else if (res.data.error.code === "processing_error") {
              errortext.value = "処理中にエラーが発生しました";
              error.value = true;
            }
          } else {
            // eslint-disable-next-line no-console
            console.log(res.data.text);
            addcardcloseandload();
            // window.location.href = '/myaccount/wallet';
            // this.$router.push("/wallet");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
          this.text = error.message;
        });
    }
  });
};

const addcardclose = () => {
  emit("addcardclose");
};

const addcardcloseandload = () => {
  emit("addcardcloseandload");
};
</script>

<template>
  <v-row class="dialogtitle pt-5 pb-5" height="100px" justify="center"
    >クレジットカード情報の追加</v-row
  >

  <div class="dialog">
    <!-- <div class="cardtitle">カード番号</div> -->
    <div class="field">
      <p class="cardno">カード番号</p>
      <div id="card-number" class="inputcard" @click="closeerrormessage()"></div>
    </div>
    <!-- <input class="cardinput" type="number" v-model="cardno" /> -->

    <!-- <div class="cardtitle">有効期限</div> -->
    <div class="field">
      <p class="cardno">有効期限</p>
      <div id="card-expiry" class="inputexpiry"></div>
    </div>

    <div class="field">
      <p class="cardno">セキュリティーコード</p>
      <div id="card-cvc" class="inputcvc"></div>
    </div>
    <!-- <input class="cvccode" type="text" placeholder="" v-model="cvc" /> -->
    <div class="credittypetitle">以下のクレジットカードをご利用いただけます。</div>
    <img src="img/visa.png" class="logopic" />
    <img src="img/mastercard.png" class="logopic" />
    <img src="img/AXP.jpg" class="logopic" />
    <img src="img/jcb.png" class="logopic" />
    <div class="errormessage" v-show="error">{{ errortext }}</div>
    <div class="bottonconteiner">
      <div class="" @click="toLink('myaccount')">
        <v-btn class="ml-5" height="30" width="80" rounded="pill" color="rgba(226, 226, 226)">
          <a class="return" @click="addcardclose()">キャンセル</a>
        </v-btn>
      </div>
      <div>
        <v-btn
          class="ml-5"
          height="30"
          width="80"
          rounded="pill"
          @click="addcardfun"
          color="rgba(89, 117, 156)"
        >
          <a class="addpayment">カードを追加</a>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardno {
  font-size: 10px;
  margin: 0 0px 0 0;
  text-align: left;
  margin: 0 0 0 20px;
}
.field {
  margin: 30px 0 0 0;
}
.inputcard {
  margin: 0 0 0 0px;
  outline: 1px solid;
  max-width: 130px;
  padding: 5px 5px 5px 5px;
}
.inputexpiry {
  margin: 0 0 0 0px;
  outline: 1px solid;
  max-width: 50px;
  padding: 5px 5px 5px 5px;
}
.inputcvc {
  margin: 0 0 0 0px;
  outline: 1px solid;
  max-width: 40px;
  padding: 5px 5px 5px 5px;
}
.addcard {
  font-size: 12px;
}

.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.box {
  border-radius: 15px;
  border: 1px solid #000;
  background-color: white;
  width: 300px;
  height: 50px;
  padding: 0.5em 1em;
  margin: 2em 0;
  margin-left: auto;
  margin-right: auto;
}
.cardbrand {
  font-size: 12px;
  margin: 8px 250px 0px 0;
}
.cardno {
  font-size: 12px;
  margin: -14px 100px 0px 0;
}
.cardexpire {
  font-size: 12px;
  margin: -17px -160px 0px 0;
}
.carddelete {
  font-size: 12px;
  color: red;
  margin: 15px -240px 0px 0;
}
.addpayment {
  color: rgb(255 255 255);
  font-size: 10px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.dialog {
  background-color: white;
  padding: 0.5em 1em;
  margin: 0 0;
  background: #fff;
  border: solid 1px;
  border-radius: 0 0 10px 10px; /*角の丸み*/
}

.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.cardtitle {
  font-size: 12px;
  margin: 20px 0 0 0;
}
.credittypetitle {
  font-size: 10px;
  margin: 10px 0 0 0;
}
.cardinput {
  outline: 1px solid;
}
.monthinput {
  outline: 1px solid;
  max-width: 50px;
}
.yearinput {
  outline: 1px solid;
  max-width: 50px;
  margin: 0 0 0 20px;
}
.cvccode {
  outline: 1px solid;
  max-width: 60px;
}
.logopic {
  max-height: 20px;
  margin: 5px 5px 0 0;
}
.errormessage {
  margin: 10px 0 0 0;
  font-size: 12px;
  color: red;
}
</style>
