<template>
  <v-app>
    <!-- <div v-if="isVerified" class="maincontainer">
      <div>
        <h2 class="mt-12 title">メールアドレス確認完了</h2>
      </div>
      <div class="mt-12">
        <p>メールアドレスの確認が完了しました</p>
      </div>
    </div> -->
    <div v-if="flagMailVerified">
      <div v-if="error" class="maincontainer">
        <div>
          <h2 class="mt-12 title">メールアドレス確認のエラー</h2>
        </div>
        <div class="mt-12">
          <p>メールアドレスの確認が既に完了しているか、URLの有効期限が切れています。</p>
        </div>
      </div>

      <div v-else class="maincontainer">
        <div>
          <h2 class="mt-12 title">メールアドレス確認中</h2>
        </div>
        <div class="mt-12">
          <p>
            メールアドレスの確認を行なっています
            <span class="dot dot1">.</span>
            <span class="dot dot2">.</span>
            <span class="dot dot3">.</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="flagResetPassword">
      <div class="mt-5 mailaddress">パスワードを入力</div>
      <v-text-field
        class="ml-10 mr-10"
        v-model="password"
        :rules="passwordRules"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        density="compact"
        required
        @click:append="toggleShow"
      />
      <v-btn color="rgba(89, 117, 156)" @click="applyChangePassword">
        <a class="passwordtitle">パスワードを変更</a></v-btn
      >
      <div v-if="passworderror" class="maincontainer">
        <div>
          <h2 class="mt-12 title">パスワード変更のエラー</h2>
        </div>
        <div class="mt-12">
          <p>パスワードの変更が既に完了しているか、URLの有効期限が切れています。</p>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getAuth, applyActionCode, confirmPasswordReset } from "firebase/auth";
const auth = getAuth();
// const isVerified = ref(false);
const error = ref(false);
const flagMailVerified = ref(false);
const flagResetPassword = ref(false);
const passworderror = ref(false);
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("oobCode");
  const mode = urlParams.get("mode");

  if (code) {
    if (mode === "resetPassword") {
      flagResetPassword.value = true;
      console.log("パスワードのリセットを行います");
    } else {
      flagMailVerified.value = true;
      console.log("メールアドレスの確認を行います");
      applyActionCode(auth, code)
        .then(() => {
          const routeTo = "/twofactorauthenticationdone";
          window.location.href = routeTo;
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
        });
    }
  } else {
    console.log("codeがありません");
  }
});

const password = ref(null);
const passwordRules = [(v) => !!v || "パスワードを入力してください"];
const show = ref(false); // この行を変更

const toggleShow = () => {
  show.value = !show.value; // refを使う場合、.valueを使ってアクセス
};
const applyChangePassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("oobCode");
  confirmPasswordReset(auth, code, password.value)
    .then(() => {
      console.log("パスワードがリセットされました");
      const routeTo = "/changepassworddone";
      window.location.href = routeTo;
    })
    .catch((error) => {
      console.error("パスワードのリセット中にエラーが発生しました", error);
      passworderror.value = true;
      // エラー処理
    });
};
</script>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dot {
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: blink;
}

.dot1 {
  animation-delay: 0.2s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.6s;
}

.passwordtitle {
  color: rgb(255 255 255);
  font-size: 12px;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
