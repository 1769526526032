<script setup>
// import { useRouter } from "vue-router";
import { toRefs } from "vue";
const props = defineProps({
  storeName: String,
  storeEmail: String,
  storePhone: String,
  storeAddress: String,
  storePerson: String,
});
const { storeName } = toRefs(props);
const { storeEmail } = toRefs(props);
const { storePhone } = toRefs(props);
const { storeAddress } = toRefs(props);
const { storePerson } = toRefs(props);
</script>

<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h4 class="mt-2">特定商取引法に基づく表記</h4>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="py-2">
                <v-list-item-content>事業者:{{ storeName }} </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>販売責任者:{{ storePerson }} </v-list-item-content>
              </v-list-item>

              <v-list-item class="py-2">
                <v-list-item-content>住所: {{ storeAddress }}</v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>電話番号:{{ storePhone }} </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content class="custom-content">
                  メールアドレス:{{ storeEmail }}<br /><br /><a href="/inquiry"
                    >※お問い合わせは、お問い合わせフォームよりお願いいたします。</a
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content
                  >販売価格: 各イベントページに記載の価格にて販売。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い方法: クレジットカードが使用できます。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  支払い時期:
                  <div>
                    クレジットカード払い:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日にお支払いといたします。
                  </div>
                  <!-- <div>
                    スマートフォンキャリア決済:
                    購入手続き完了時に課金され､お客様の契約に基づいた引き落とし日に携帯料金と合算でのお支払いとなります。
                  </div> -->
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  商品の引渡し:
                  各イベントページに記載の店舗にてお引き渡し、店舗内のみにて飲食が可能です。持ち帰りは不可とさせていただきます。引渡し期限は購入日より90日間といたします。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-2">
                <v-list-item-content>
                  商品の返品:商品のキャンセル、返品、交換は一切受け付けておりません。<br />ただし、商品が明らかに不良品の場合のみ交換いたします。<br />万が一、交換商品が無い場合は商品代金を返金または代品での対応とさせて頂きます。
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped></style>
