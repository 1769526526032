import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createGtm } from "vue-gtm";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
//環境変数
const apiKey = process.env.VUE_APP_FIREBASE_APIKEY;
const authDomain = process.env.VUE_APP_FIREBASE_AUTHDOMAIN;
const projectId = process.env.VUE_APP_FIREBASE_PROJECTID;
const storageBucket = process.env.VUE_APP_FIREBASE_STORAGEBUCKET;
const messagingSenderId = process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.VUE_APP_FIREBASE_APPID;
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
};
const GTMID = process.env.VUE_APP_GTM_ID; // 追加
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const auth = getAuth(app);
getFunctions(app);
export default db; //追加

loadFonts();

const vueApp = createApp(App);
vueApp.use(router);
vueApp.use(vuetify);
vueApp.use(createGtm({ id: GTMID })); // 環境変数からGTMIDを使用
vueApp.mount("#app");
// createApp(App).use(router).use(vuetify).mount("#app");
