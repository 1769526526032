<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  // 商品ランクごとに分類された商品リスト {"ランク文字列": [商品オブジェクト, ...], ...}
  items: {
    type: Object,
    required: true,
  },
  activeItems: {
    type: Array,
    required: true,
  },
})

// 双方向バインディング
const emit = defineEmits(['update:activeItems'])
let computedActiveItems = computed({
  get: () => props.activeItems,
  set: (value) => {
    emit('update:activeItems', value)
  },
})

// 商品選択制御処理
const isActive = (item) => {
  return computedActiveItems.value.includes(item)
}
const toggleActive = (item) => {
  const index = computedActiveItems.value.indexOf(item)
  if (index >= 0) {
    computedActiveItems.value.splice(index, 1)
  } else {
    computedActiveItems.value.push(item)
  }
}
const selectAllProducts = (rank) => {
  deselectAllProducts(rank)
  computedActiveItems.value.push(...props.items[rank])
}
const deselectAllProducts = (rank) => {
  const keep = computedActiveItems.value.filter((item) => !(item.product_rank_ja === rank))
  computedActiveItems.value.splice(0, computedActiveItems.value.length)
  computedActiveItems.value.push(...keep)
}
const isSelectedAllProducts = (rank) => {
  return computedActiveItems.value.filter((item) => (item.product_rank_ja == rank)).length == props.items[rank].length
}

// リストの開閉制御
const open = ref([])

</script>

<template>
  <v-list lines="one" v-model:opened="open">

    <v-list-group v-for="rank in Object.keys(props.items).sort()" :key="rank" :value="rank" :title="rank">

      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-gift-outline" :title="rank" variant="elevated"></v-list-item>
      </template>

      <div v-if="!isSelectedAllProducts(rank)">
        <v-btn class="my-2" height="35" width="100" rounded="pill" @click="selectAllProducts(rank)">すべて選択</v-btn>
      </div>
      <div v-else>
        <v-btn class="my-2" height="35" width="100" rounded="pill" @click="deselectAllProducts(rank)"
          style="background-color: #CFD8DC;">すべて解除</v-btn>
      </div>

      <v-list-item v-for="item in props.items[rank]" :key="item.created_at_human" :active="isActive(item)"
        @click="toggleActive(item)" :title="item.product_name" :prepend-avatar="item.product_image"
        color="blue-grey-lighten-4" variant="elevated">
        <v-list-item-subtitle>購入日時: {{ item.created_at_human }}</v-list-item-subtitle>
      </v-list-item>

    </v-list-group>

  </v-list>
</template>

<style scoped>
</style>
